<template>
  <v-row>
    <v-container
      class="pt-0"
      :style="{
        maxWidth: !$vuetify.breakpoint.smAndUp ? 'unset !important' : '',
      }"
    >
      <v-row v-show="loadContent" class="fill-height" align-content="center" justify="center" style="height:60vh">
        <v-col class="text-subtitle-1 text-center color-navy-soft" cols="12">
          Tunggu beberapa saat ...
        </v-col>
        <v-col cols="6">
          <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
      </v-row>
      <v-row v-show="content">
        <v-col cols="12" sm="12">
          <v-row no-gutters>
            <v-btn class="pl-0 pr-2 mt-4" text color="primary" @click="$router.push('/kelas')">
              <v-icon class="mr-2">$prev</v-icon>
              Kelas saya
            </v-btn>
            <template v-if="$vuetify.breakpoint.smAndUp">
              <div v-if="program.type === 'hybrid' || program.type === 'offline'" class="pl-4 col-md-4 ml-auto">  
                <v-card class="elevation-0" @click="showScan=true;" style="cursor: pointer;">
                  <v-card-actions>
                    <v-btn text>
                      <img src="@/assets/images/barcode.svg" width="18" class="mr-2">
                      QR Code Absen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon>
                      <v-icon class="">ri-arrow-right-line</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </div>
            </template>
            <v-spacer v-if="!$vuetify.breakpoint.smAndUp"></v-spacer>
            <v-btn
              v-if="!$vuetify.breakpoint.smAndUp"
              rounded
              small
              color="teal"
              text
              class="white px-2 mt-4"
              @click="showActivity = true"
            >
              <div class="d-flex">
                <v-icon small class="d-block mr-1">ri-lightbulb-line</v-icon>
                Aktivitas
              </div>
            </v-btn>
          </v-row>
        </v-col>

        <v-col cols="12" sm="8" class="mb-3">
          <template v-if="isLoading">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <v-col>
                <div class="text-center">
                  <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </template>
          <template v-else-if="act.type == 'menonton_video'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3">{{ act.title }}</div>
              <v-col cols="12" class="pa-0">
                <v-card elevation="0">
                  <v-responsive :aspect-ratio="16 / 9">
                    <video id="videoElement" ref="videoPlayer" v-show="showVideo" width="100%" height="auto" controls playsinline @ended="ended(act.is_complete)" controlslist="nodownload noremoteplayback noplaybackrate" class="videoku" :allowKeyboard = 'false' >
                      <!-- <source :src="video_url" type="video/mp4"> -->
                      <source :src="video_url" type="video/mp4">
                      <!-- <source :src="act.theory.file.url" type="video/mp4"> -->
                    </video>
                    <!-- <video id="vid1" src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/videos/1633318090279470491-1-open.mp4" controls class="video-js vjs-default-skin vjs-big-play-centered" width="640" height="360" preload="auto"></video> -->
                    <div v-show="showError" class="text-center">                      
                      Your Browser does not support the video tag
                    </div>
                    <!-- <video-player ref="videoPlayer" :options="videoOptions" @ended="ended" playsinline/> -->
                  </v-responsive>
                </v-card>
              </v-col>
              <v-card elevation="0" class="col-12">
                <div id="desc-video" class="pa-4">
                  <p class="text-body-2 text-justify mb-4" v-html="act.theory.description"></p>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'pre_test'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <v-card v-if="act.is_complete == true || act.repeat_test > 0" class="col-md-12" elevation="0">
                <div v-show="resultNilai" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>
                        <template v-if="parseInt(act.settings.opportunity_repeat_test - act.repeat_test) < 0">
                          0x
                        </template>
                        <template v-else>
                          {{ act.settings.opportunity_repeat_test - act.repeat_test }}x
                        </template>
                      </div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>
                        <span class="text-h4 font-weight-bold">/ 100 </span>
                      </div>
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPre = true;
                          resultNilai = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPre = true;
                          resultNilai = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      > -->
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test=== 0 || (act.settings.opportunity_repeat_test - act.repeat_test) === 0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirect()">
                          <span>Ulangi Pre Test</span>
                        </v-btn>
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQues" class="pa-4">
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon></v-btn
                      >
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.settings.number_questions_package }}
                      </div>
                      <v-btn v-if="act.settings.is_skip === false" small outlined color="primary" :disabled="isRequired" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                      <v-btn v-else outlined color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-md-4">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                      <p v-else></p>
                    </div>
                    <!-- <v-spacer></v-spacer> -->
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" class="rounded-pill" dark small @click="submitReview(act.settings)">
                            <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                            Submit
                          </v-btn>                        
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                      <div v-else>
                        
                      </div>
                    </div>
                  </div>  
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text">
                      </div>
                      <div v-if="currentQuestion.test.question.media != ''">
                        <audio-player
                          class="d-inline-block"
                          :src="currentQuestion.test.question.media[0].public_url"
                        />
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestion"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="250px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div v-show="showExplanationPre" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilai = true;
                            showExplanationPre = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list v-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestion"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiFalse" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>
                        <template v-if="parseInt(act.settings.opportunity_repeat_test - act.repeat_test) < 0">
                          0x
                        </template>
                        <template v-else>
                          {{ act.settings.opportunity_repeat_test - act.repeat_test }}x
                        </template>
                      </div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>
                        <span class="text-h4 font-weight-bold">/ 100 </span>
                      </div>
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPreFalse = true;
                          resultNilaiFalse = false;
                          getExplan(act.settings.is_show_answer)
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPreFalse = true;
                          resultNilaiFalse = false;
                          getExplan(act.settings.is_show_answer)
                        "
                        >Lihat Jawaban</v-btn
                      > -->
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test===0 || (act.settings.opportunity_repeat_test - act.repeat_test) === 0">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectFalse()">
                          <span>Ulangi Pre Test</span>
                        </v-btn>
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesFalse" class="pa-4">
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon></v-btn
                      >
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.settings.number_questions_package }}
                      </div>
                      <v-btn v-if="act.settings.is_skip === false" small outlined color="primary" :disabled="isRequired" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                      <v-btn v-else outlined color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-md-4">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black text-center" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                      <p v-else></p>
                    </div>
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="primary"
                            dark
                            block
                            class="rounded-pill"
                            small
                            @click="submitReviewFalse(act.settings)"
                          >
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text">
                      </div>
                      <div v-if="currentQuestion.test.question.media != ''">
                        <audio-player
                          class="d-inline-block"
                          :src="currentQuestion.test.question.media[0].public_url"
                        />
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestion"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="250px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationPreFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn icon small class="mr-1" @click="act.current--" :disabled="act.current <= 1">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn icon small :disabled="act.current >= act.total_question" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilaiFalse = true;
                            showExplanationPreFalse = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list v-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestion"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'quiz'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <v-card v-if="act.is_complete == true || act.repeat_test > 0" class="col-md-12" elevation="0">
                <div v-show="resultNilai" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>
                        <template v-if="parseInt(act.settings.opportunity_repeat_test - act.repeat_test) < 0">
                          0x
                        </template>
                        <template v-else>
                          {{ act.settings.opportunity_repeat_test - act.repeat_test }}x
                        </template>
                      </div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanation = true;
                          resultNilai = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      > 
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanation = true;
                          resultNilai = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >  -->
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) === 0">
                        
                      </div>                      
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirect(act.type,act.settings)" :loading="activity_loading"
                          >Ulangi Kuis</v-btn
                        >
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false && act.score >= act.settings.set_minimum_value.minimum_value" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQues" class="pa-4">
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon></v-btn
                      >
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.settings.number_questions_package }}
                      </div>
                      <v-btn v-if="act.settings.is_skip === false" small outlined color="primary" :disabled="isRequired" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                      <v-btn v-else outlined color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>                    
                    <!-- <v-spacer></v-spacer> -->
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" dark color="primary" class="rounded-pill" block small @click="submitQuiz(act.settings)">
                            <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                      <div v-else>
                        
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>                                                    
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text">
                      </div>
                      <div v-if="currentQuestion.test.question.media != ''">
                        <audio-player
                          class="d-inline-block"
                          :src="currentQuestion.test.question.media[0].public_url"
                        />
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestion"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="250px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanation" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn outlined color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1 mt-2 text-center">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn outlined color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="primary"
                          class="rounded-pill"
                          @click="
                            resultNilai = true;
                            showExplanation = false;
                            act.current=1;
                          "
                        >
                          Submit
                          <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                  
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list v-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>                  
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiFalse" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>
                        <template v-if="parseInt(act.settings.opportunity_repeat_test - act.repeat_test) < 0">
                          0x
                        </template>
                        <template v-else>
                          {{ act.settings.opportunity_repeat_test - act.repeat_test }}x
                        </template>
                      </div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>
                        <span class="text-h4 font-weight-bold">/ 100 </span>
                      </div>
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationFalse = true;
                          resultNilaiFalse = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationFalse = true;
                          resultNilaiFalse = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      > --> 
                      <v-spacer class="py-2"></v-spacer>                      
                      <div v-if="act.settings.opportunity_repeat_test === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) === 0 ">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectFalse(act.type,act.settings)" :loading="activity_loading">
                          <span>Ulangi Kuis</span>
                        </v-btn>
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false && act.score >= act.settings.set_minimum_value.minimum_value" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>  
                </div>
                <div v-show="showQuesFalse" class="pa-4">
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined color="primary" small class="mr-1" :disabled="act.current <= 1" @click="act.current--" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.settings.number_questions_package }}
                      </div>
                      <v-btn v-if="act.settings.is_skip === false" small outlined color="primary" :disabled="isRequired" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                      <v-btn v-else outlined color="primary" small :disabled="act.current === act.settings.number_questions_package" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            dark
                            color="primary"
                            small
                            block
                            class="rounded-pill"
                            @click="submitQuizFalse(act.settings)"
                          >
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text">
                      </div>
                      <div v-if="currentQuestion.test.question.media != ''">
                        <audio-player
                          class="d-inline-block"
                          :src="currentQuestion.test.question.media[0].public_url"
                        />
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestion"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="250px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                      <div v-if="review">
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" v-model="correct_answer" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden" v-for="item in 1" :key="item">
                              <v-list-item-content>
                                <div class="mb-4 green--text">Jawaban yang benar adalah {{ "Opsi " + item }}</div>
                                <div class="navy-soft--text">
                                  In feugiat id nec habitant tortor. Faucibus montes.
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>                  
                </div>
                <div v-show="showExplanationFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn outlined color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1 mt-2 text-center">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn outlined color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="primary"
                          class="rounded-pill"
                          @click="
                            resultNilaiFalse = true;
                            showExplanationFalse = false;
                            act.current=1;
                          "
                        >
                          Submit
                          <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                                                     
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list v-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'post_test'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <v-card v-if="act.is_complete == true" class="col-md-12" elevation="0">
                <div v-show="resultNilaiPost" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>
                        <template v-if="parseInt(act.settings.opportunity_repeat_test - act.repeat_test) < 0">
                          0x
                        </template>
                        <template v-else>
                          {{ act.settings.opportunity_repeat_test - act.repeat_test }}x
                        </template>
                      </div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>                    
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPost = true;
                          resultNilaiPost = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPost = true;
                          resultNilaiPost = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      > -->
                      <v-spacer class="py-2"></v-spacer>                      
                      <div v-if="act.settings.opportunity_repeat_test === 0 ">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectPost(act.type,act.settings)" :loading="activity_loading"
                          >Ulangi Post Test</v-btn
                        >
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesPost" class="pa-4">
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small color="primary" class="mr-1" :disabled="act.current <= 1" @click="act.current--" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.settings.number_questions_package }}
                      </div>

                      <v-btn v-if="act.settings.is_skip === false" outlined color="primary" small :disabled="isRequired" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>                      
                      </v-btn>
                      <v-btn v-else small color="primary" outlined :disabled="act.current === act.settings.number_questions_package" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>                      
                      </v-btn>
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>
                    <!-- <v-btn icon small :disabled="isRequired" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    > -->
                    <!-- <v-spacer></v-spacer> -->
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" dark class="rounded-pill" block color="primary" small @click="submitPost(act.settings)">
                            <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                      <div v-else>
                        
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>                                                    
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <!-- <div id="tagImg" class="text-justify" v-html="currentQuestion.question.text"> -->
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <div v-if="currentQuestion.test.question.media != ''">                        
                        <audio-player
                          class="d-inline-block"
                          :src="currentQuestion.test.question.media[0].public_url"                       
                        />
                      </div>
                      <!-- <v-list class="options" :disabled="review">
                        <v-list-item-group
                          :multiple="review"
                          class="snow"
                          v-model="currentQuestion.answer"
                          color="primary"
                        >
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestion"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list> -->
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestion"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div v-html="item.text"></div>
                          </template>
                        </v-radio>
                      </v-radio-group>                    
                    </div>
                  </div>                  
                </div>
                <div v-show="showExplanationPost" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn outlined color="primary" small class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1 mt-2 text-center">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn outlined color="primary" small :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          small
                          color="primary"
                          class="rounded-pill"
                          @click="
                            resultNilaiPost = true;
                            showExplanationPost = false;
                            act.current=1;
                          "
                        >
                          Submit
                          <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                  
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list v-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>                  
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiPostFalse" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="8" class="d-flex justify-space-between">
                      <div>Kesempatan mengulang</div>
                      <div v-if="act.settings.opportunity_repeat_test === -1">Tanpa Batas</div>
                      <div v-else>
                        <template v-if="parseInt(act.settings.opportunity_repeat_test - act.repeat_test) < 0">
                          0x
                        </template>
                        <template v-else>
                          {{ act.settings.opportunity_repeat_test - act.repeat_test }}x
                        </template>
                      </div>
                    </v-col>
                    <v-col v-if="act.settings.set_minimum_value.is_set === true" cols="8" class="d-flex justify-space-between">
                      <div>Nilai minimal</div>
                      <div>
                        {{ act.settings.set_minimum_value.minimum_value }}
                      </div>
                    </v-col>
                    <v-col cols="8">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col v-if="act.settings.is_show_score === true" cols="8">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h3 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span>
                      </div>
                    </v-col>
                    <v-col md="6" sm="12">
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPostFalse = true;
                          resultNilaiPostFalse = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPostFalse = true;
                          resultNilaiPostFalse = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >  -->
                      <v-spacer class="py-2"></v-spacer>
                      <div v-if="act.settings.opportunity_repeat_test === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) === 0 ">
                        
                      </div>
                      <div v-else>
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectPostFalse(act.type,act.settings)" :loading="activity_loading"
                          >Ulangi Post Test</v-btn
                        >
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <div>
                        <template v-if="act.settings.set_minimum_value.is_set">
                          <v-btn
                            v-if="act.score >= act.settings.set_minimum_value.minimum_value && act.is_complete == false"
                            class="mx-2"
                            color="primary"
                            rounded
                            block
                            @click="getUnlockUpload()"
                            :loading="loadUpload">
                            Klik activity selanjutnya
                          </v-btn>
                        </template>
                        <template v-else>
                          <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                            Klik activity selanjutnya
                          </v-btn>
                        </template>
                      </div>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesPostFalse" class="pa-4">
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small color="primary" class="mr-1" :disabled="act.current <= 1" @click="act.current--" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon>
                      </v-btn>
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.settings.number_questions_package }}
                      </div>

                      <v-btn v-if="act.settings.is_skip === false" outlined color="primary" small :disabled="isRequired" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                      <v-btn v-else small color="primary" outlined :disabled="act.current === act.settings.number_questions_package" @click="act.current++" style="min-width: auto;width: 30px!important;">
                        <v-icon>$next</v-icon>
                      </v-btn>
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" dark class="rounded-pill" block color="primary" small @click="submitPostFalse(act.settings)">
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestion.test.question.text">
                      </div>
                      <div v-if="currentQuestion.test.question.media != ''">
                        <audio-player
                          class="d-inline-block"
                          :src="currentQuestion.test.question.media[0].public_url"
                        />
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestion.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestion"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div style="display: block;">
                              <div v-html="item.text"></div>
                              <img width="250px" height="auto" class="mt-1 rounded-lg" :src="item.media[0].public_url" v-if="item.media.length > 0">
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>
                </div>
                <div v-show="showExplanationPostFalse" class="pa-4">
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn outlined color="primary" small class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1 mt-2 text-center">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.settings.number_questions_package }}
                    </div>
                    <v-btn outlined color="primary" small :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          @click="
                            resultNilaiPostFalse = true;
                            showExplanationPostFalse = false;
                            act.current=1;
                          "
                        >
                          <v-icon>ri-logout-circle-r-line</v-icon>
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                                                      
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                        <!-- {{ currentQuestion.question.text }} -->
                      </div>
                      <v-list v-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion === true && act.settings.is_show_answer === true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text">
                              <!-- {{ item.text }}  -->
                            </v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'exam'">
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp">
              <div class="font-weight-bold mb-3 text-capitalize ms-3 text-h6">
                {{ act.title }}
              </div>

              <v-card v-if="act.is_complete == true || act.repeat_test > 0" class="col-md-12" elevation="0">
                <div v-show="resultNilaiExam" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="60">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="10">
                      <v-data-table
                        class="custom-table"
                        :headers="headers_nilai"
                        :items="act.section"
                        hide-default-footer
                        hide-default-sort-column>
                        <template  v-slot:[`item.result_score`]="{ item }">
                          {{ item.score >= item.min_score ? "Lulus" : "Tidak Lulus" }}
                        </template>
                      </v-data-table>
                      <!-- <v-simple-table fixed-header class="custom-table">
                        <template v-slot:default>
                          <thead style="background-color: #f3f3f3">
                            <tr style="background-color: #f3f3f3">
                              <th class="text-left">Jenis Test</th>
                              <th class="text-center">Nilai Minimal</th>
                              <th class="text-center">Hasil Nilai Test</th>
                              <th class="text-center">Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in act.section"
                              :key="item.name"
                            >
                              <td>{{ item.name }}</td>
                              <td class="text-center">{{ item.min_score }}</td>
                              <td class="text-center">{{ item.score }}</td>
                              <td class="text-center">{{ item.score >= item.min_score ? "Lulus" : "Tidak Lulus" }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table> -->
                    </v-col>

                    <v-col v-if="act.settings.is_show_score === true" cols="8" class="text-center">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text mt-3">
                        <span class="text-h2 font-weight-bold">
                          {{ act.score }}
                        </span>

                        <!-- <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span> -->
                      </div>
                      <div v-if="result_exam == false" class="font-weight-bold mt-2">
                        Keterangan : Tidak Lulus
                      </div>
                      <div v-else class="font-weight-bold mt-2">
                        Keterangan : Lulus
                      </div>

                      <div class="mt-2" v-if="act.settings.opportunity_repeat_test === -1">Kesempatan mengulang : Tanpa Batas</div>
                      <!-- <div class="mt-2" v-else-if="(act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">Kesempatan mengulang : -</div> -->
                      <div class="mt-2" v-else>Kesempatan mengulang : 
                        <span v-if="act.settings.opportunity_repeat_test + 1 - act.repeat_test > 0">{{ act.settings.opportunity_repeat_test + 1 - act.repeat_test }}</span>
                        <span v-else>0</span>
                        x</div>
                    </v-col>
                    <v-col md="6" sm="12">                      
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationExam = true;
                          resultNilaiExam = false;
                          getExplanExam();
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationPost = true;
                          resultNilaiPost = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      > --> 
                      <v-spacer class="py-2"></v-spacer>                      
                      <!-- <div v-if="act.settings.opportunity_repeat_test === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">
                        
                      </div> -->
                      <div v-if="act.settings.opportunity_repeat_test == -1 || act.repeat_test < act.settings.opportunity_repeat_test + 1">
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectExam(act.type,act.settings)" :loading="activity_loading"
                          >Ulangi Test</v-btn
                        >
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesExam" class="pa-4">
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group                      
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                    <v-btn
                      v-for="index in act.total_question"
                      :key="index"
                      :class="question_status(index)"
                      x-small
                      outlined
                      class="mx-2 my-2 fs-12 py-3 text-justify"
                      style="width: 35px;"
                      elevation="0"
                      @click="goNumber(index)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </div> -->
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon></v-btn
                      >
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.total_question }}
                      </div>
                      <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                        ><v-icon>$next</v-icon></v-btn
                      >
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>
                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.settings.number_questions_package" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" small block color="primary" dark class="rounded-pill" @click="submitExam(act.settings)">
                            <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                      <div v-else>
                        
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>                                    
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExam.question.text">
                      </div>
                      <div v-if="currentQuestionExam.question.media != ''">    
                        <div v-if="currentQuestionExam.question.media[0].type == 'image'">
                          <v-img :src="currentQuestionExam.question.media[0].public_url"></v-img>
                        </div>      
                        <div v-else>
                          <audio-player
                            class="d-inline-block"
                            :src="currentQuestionExam.question.media[0].public_url"                       
                          />
                        </div>              
                      </div>
                      <v-radio-group hide-details="auto" v-model="currentQuestionExam.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestionExam"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div v-if="item.media != ''">    
                              <div>
                              <!-- <div v-if="item.media[index].type == 'image'"> -->
                                <v-img :src="item.media[0].public_url"></v-img>
                              </div>      
                              <!-- <div v-else>
                                <audio-player
                                  class="d-inline-block"
                                  :src="item.media[index].public_url"                       
                                />
                              </div> -->              
                            </div>
                            <div v-html="item.text"></div>
                          </template>
                        </v-radio>
                      </v-radio-group>                    
                    </div>
                  </div>                  
                </div>
                <div v-show="showExplanationExam" class="pa-4">
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                      <v-btn
                        v-for="index in act.total_question"
                        :key="index"
                        :class="question_status(index)"
                        x-small
                        outlined
                        class="mx-2 my-2 fs-12 py-3 text-justify"
                        style="width: 35px;"
                        elevation="0"
                        @click="goNumber(index)"
                        v-bind:id="index"
                      >
                        {{ index }}
                      </v-btn>
                  </div> -->
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group                      
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <div class="pa-4 d-flex align-center navy-soft--text">
                    <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.total_question }}
                    </div>
                    <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          dark 
                          small
                          color="primary"
                          class="rounded-pill"
                          @click="
                            resultNilaiExam = true;
                            showExplanationExam = false;
                            act.current=1;
                          "
                        >
                          Submit
                          <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                                                        
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                      </div>
                      <v-list v-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text"></v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>

                                <!-- nanti di comment lagi
                                <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->

                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- nanti di comment lagi -->
                                  <!-- {{ explanation.text }} -->
                                  
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>                  
                </div>
              </v-card>
              <v-card v-else class="col-12" elevation="0">
                <div v-show="resultNilaiExamFalse" class="pa-4">
                  <v-row class="py-4" justify="center">
                    <v-col cols="8" class="d-flex justify-center">
                      <v-icon class="d-block" color="grey lighten-2" size="96">ri-file-edit-fill</v-icon>
                    </v-col>
                    <v-col v-if="false" cols="8" class="d-flex justify-space-between">
                      <div>Jumlah soal minimal</div>
                      <div></div>
                    </v-col>
                    <v-col cols="10">
                      <v-data-table
                        class="custom-table"
                        :headers="headers_nilai"
                        :items="act.section"
                        hide-default-footer
                        hide-default-sort-column>
                        <template  v-slot:[`item.result_score`]="{ item }">
                          {{ item.score >= item.min_score ? "Lulus" : "Tidak Lulus" }}
                        </template>
                      </v-data-table>
                      <!-- <v-simple-table fixed-header class="custom-table">
                        <template v-slot:default>
                          <thead style="background-color: #f3f3f3">
                            <tr style="background-color: #f3f3f3">
                              <th class="text-left">Jenis Test</th>
                              <th class="text-center">Nilai Minimal</th>
                              <th class="text-center">Hasil Nilai Test</th>
                              <th class="text-center">Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in act.section"
                              :key="item.name"
                            >
                              <td>{{ item.name }}</td>
                              <td class="text-center">{{ item.min_score }}</td>
                              <td class="text-center">{{ item.score }}</td>
                              <td class="text-center">{{ item.score >= item.min_score ? "Lulus" : "Tidak Lulus" }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table> -->
                    </v-col>

                    <v-col v-if="act.settings.is_show_score === true" cols="8" class="text-center">
                      <div class="text-center font-weight-bold">Nilai</div>
                      <div class="text-center primary--text">
                        <span class="text-h2 font-weight-bold mt-3">
                          {{ act.score }}
                        </span>

                        <!-- <span class="text-h4 font-weight-bold"
                          >/ 100
                        </span> -->
                      </div>
                      <div v-if="result_exam == false" class="font-weight-bold mt-2">
                        Keterangan : Tidak Lulus
                      </div>
                      <div v-else class="font-weight-bold mt-2">
                        Keterangan : Lulus
                      </div>
                      <div class="mt-2" v-if="act.settings.opportunity_repeat_test === -1">Kesempatan mengulang : Tanpa Batas</div>
                      <!-- <div class="mt-2" v-else-if="(act.settings.opportunity_repeat_test - act.repeat_test) === 0 || (act.settings.opportunity_repeat_test - act.repeat_test) < -1">Kesempatan mengulang : -</div> -->
                      <div class="mt-2" v-else>Kesempatan mengulang : 
                        <span v-if="act.settings.opportunity_repeat_test + 1 - act.repeat_test > 0">{{ act.settings.opportunity_repeat_test + 1 - act.repeat_test }}</span>
                        <span v-else>0</span>
                        x</div>
                    </v-col>
                    <v-col md="6" sm="12">                      
                      <v-btn
                        v-if="act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationExamFalse = true;
                          resultNilaiExamFalse = false;
                          getExplanExam();
                        "
                        >Lihat Jawaban</v-btn
                      >
                      <!-- <v-btn
                        v-else-if="act.settings.opportunity_repeat_test > 0 && act.repeat_test >= act.settings.opportunity_repeat_test  && act.settings.is_show_discussion"
                        rounded
                        block
                        color="primary"
                        elevation="0"
                        large
                        @click="
                          showExplanationExamFalse = true;
                          resultNilaiExamFalse = false;
                          getExplan(act.settings.is_show_answer);
                        "
                        >Lihat Jawaban</v-btn
                      >  -->
                      <v-spacer class="py-2"></v-spacer>

                      <!-- nanti di comment lagi
                      <div v-if="act.type === 'Exam_test' && act.score >= act.settings.set_minimum_value.minimum_value"></div> -->


                      <div v-if="act.settings.opportunity_repeat_test == -1 || act.repeat_test < act.settings.opportunity_repeat_test + 1">
                        <v-btn rounded block text color="primary" elevation="0" large @click="redirectExamFalse(act.type,act.settings)" :loading="activity_loading"
                          >Ulangi Test</v-btn
                        >
                      </div>
                      <v-spacer class="py-2"></v-spacer>
                      <v-btn v-if="act.is_complete == false" class="mx-2" color="primary" rounded block @click="getUnlockUpload()" :loading="loadUpload">
                        Klik activity selanjutnya
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-show="showQuesExamFalse" class="pa-4">
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group                      
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                    <v-btn
                      v-for="index in act.total_question"
                      :key="index"
                      :class="question_status(index)"
                      x-small
                      outlined
                      class="mx-2 my-2 fs-12 py-3 text-justify"
                      style="width: 35px;"
                      elevation="0"
                      @click="goNumber(index)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </div> -->
                  <div class="pa-4 row align-center navy-soft--text">
                    <div id="box-navigasi" class="col-md-5 d-flex">
                      <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                        <v-icon>$prev</v-icon></v-btn
                      >
                      <div class="mr-1 mt-2 text-center">
                        Soal no
                        <span class="primary--text">{{ act.current }}</span> dari
                        {{ act.total_question }}
                      </div>
                      <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                        ><v-icon>$next</v-icon></v-btn
                      >
                    </div>
                    <div class="col-md-4 text-center">
                      <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                        {{ totalSeconds | minutesAndSeconds }}
                      </p>
                    </div>
                    <!-- nanti di comment lagi
                    <v-btn v-if="act.settings.is_skip === false" icon small :disabled="isRequired" @click="act.current++">
                      <v-icon>$next</v-icon>
                    </v-btn>
                    <v-btn v-else icon small :disabled="act.current === act.settings.number_questions_package" @click="act.current++">
                      <v-icon>$next</v-icon>
                    </v-btn> -->


                    <!-- <p v-if="act.settings.is_show_timer === true" class="font-weight-bold fs-20 mb-0 pa-2 color-black" color="primary">
                      {{ totalSeconds | minutesAndSeconds }}
                    </p> -->

                    <!-- nanti di comment lagi
                    <v-btn icon small :disabled="isRequired" @click="act.current++"
                      ><v-icon>$next</v-icon></v-btn
                    > -->


                    <div class="col-md-3">
                      <v-tooltip v-if="!review && act.current === act.total_question" color="grey darken-3" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            block
                            color="primary"
                            dark
                            small
                            class="rounded-pill"
                            @click="submitExamFalse(act.settings)"
                          >
                            <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                            Submit
                          </v-btn>
                        </template>
                        <span>Submit</span>
                      </v-tooltip>
                      <div v-else>
                        
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>                                                      
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExam.question.text"></div>
                      <div v-if="currentQuestionExam.question.media != ''">    
                        <div v-if="currentQuestionExam.question.media[0].type == 'image'">
                          <v-img :src="currentQuestionExam.question.media[0].public_url"></v-img>
                        </div>      
                        <div v-else>
                          <audio-player
                            class="d-inline-block"
                            :src="currentQuestionExam.question.media[0].public_url"                       
                          />
                        </div>              
                      </div>
                      
                      <v-radio-group hide-details="auto" v-model="currentQuestionExam.answer" class="options">
                        <v-radio
                          v-for="(item, index) in optionQuestionExam"
                          :key="index"
                          class="mb-2"
                          :value="index"
                        >
                          <template v-slot:label>
                            <div v-if="item.media != ''">    
                              <div>
                              <!-- <div v-if="item.media[index].type == 'image'"> -->
                                <v-img :src="item.media[0].public_url"></v-img>
                              </div>      
                              <!-- <div v-else>
                                <audio-player
                                  class="d-inline-block"
                                  :src="item.media[index].public_url"                       
                                />
                              </div>  -->   
                            </div>
                            <div v-html="item.text"></div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </div>
                  </div>                  
                </div>
                <div v-show="showExplanationExamFalse" class="pa-4">
                  <v-sheet
                    class="mx-auto"
                    max-width="98%"
                  >
                    <v-slide-group                      
                      show-arrows
                    >
                      <v-slide-item
                        v-for="index in act.total_question"
                        :key="index"
                      >
                        <v-btn
                          :class="question_status(index)"
                          x-small
                          outlined
                          class="mx-2 my-2 fs-12 py-3 text-justify"
                          style="width: 35px;"
                          elevation="0"
                          @click="goNumber(index)"
                          v-bind:id="index"
                        >
                          {{ index }}
                        </v-btn>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                  <!-- <div style="white-space: nowrap; overflow-x: auto" class="py-3">
                    <v-btn
                      v-for="index in act.total_question"
                      :key="index"
                      :class="question_status(index)"
                      x-small
                      outlined
                      class="mx-2 my-2 fs-12 py-3 text-justify"
                      style="width: 35px;"
                      elevation="0"
                      @click="goNumber(index)"
                      v-bind:id="index"
                    >
                      {{ index }}
                    </v-btn>
                  </div> -->
                  <div class="pa-4 d-flex align-center navy-soft--text">

                    <v-btn outlined small color="primary" class="mr-1" @click="act.current--" :disabled="act.current <= 1" style="min-width: auto;width: 30px!important;">
                      <v-icon>$prev</v-icon></v-btn
                    >
                    <div class="mr-1">
                      Soal no
                      <span class="primary--text">{{ act.current }}</span> dari
                      {{ act.total_question }}
                    </div>
                    <v-btn outlined small color="primary" :disabled="act.current >= act.total_question" @click="act.current++" style="min-width: auto;width: 30px!important;"
                      ><v-icon>$next</v-icon></v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="!review" color="grey darken-3" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          dark
                          small
                          color="primary"
                          class="rounded-pill"
                          @click="
                            resultNilaiExamFalse = true;
                            showExplanationExamFalse = false;
                            act.current=1;
                          "
                        >
                          Submit
                          <!-- <v-icon>ri-logout-circle-r-line</v-icon> -->
                        </v-btn>
                      </template>
                      <span>Submit</span>
                    </v-tooltip>
                  </div>
                  <v-divider></v-divider>                                   
                  <div v-if="activity_loading" class="pa-4 text-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                  </div>
                  <div v-else class="pa-4 d-flex" style="user-select: none;max-height: 64vh;overflow: scroll;">
                    <div class="navy-soft--text" style="width: 2rem">{{ act.current }}.</div>
                    <div class="col pa-0">
                      <div id="tagImg" class="text-justify" v-html="currentQuestionExplan.text">
                      </div>
                      <v-list v-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == false" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow" color="primary">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="review ? (item == 2 ? 'green' : item == 4 ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content>
                              {{ item.text }}
                            </v-list-item-content>
                            <v-list-item-icon v-if="review && [2, 4].includes(item)">
                              <v-icon>
                                {{ item == 2 ? "ri-checkbox-circle-fill" : "ri-close-circle-fill" }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-list v-else-if="act.settings.is_show_discussion == true && act.settings.is_show_answer == true" class="options" disabled>
                        <v-list-item-group :multiple="review" class="snow">
                          <v-list-item
                            class="mb-4 py-3 px-4 rounded overflow-hidden"
                            :color="answerExplan ? (answerExplan == true ? 'green' : answerExplan == false ? 'error' : '') : ''"
                            v-for="(item, index) in optionQuestionExplan"
                            :key="index"
                          >
                            <v-list-item-content :class="item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false ? 'success--text font-weight-bold' : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? 'error--text font-weight-bold' : '' " v-html="item.text"></v-list-item-content>
                            <v-list-item-icon >
                              <v-icon>
                                {{ item.text === answerTextExplan && answerExplan === true && correctAnswer === true || item.text === correctTextExplan && answerExplan === true && correctAnswer === false  ? "ri-checkbox-circle-fill success--text" : item.text === answerTextExplan && answerExplan === true && correctAnswer === false ? "ri-close-circle-fill error--text" : '' }}
                              </v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <div>
                        <div class="font-weight-bold">Pembahasan soal:</div>
                        <v-list class="options" disabled>
                          <v-list-item-group class="snow" color="primary">
                            <v-list-item class="mb-4 py-3 px-4 rounded overflow-hidden">
                              <v-list-item-content>
                                <!-- nanti di comment lagi
                                <div class="mb-4 green--text">
                                  Jawaban yang benar adalah {{ "Opsi " + item }}
                                </div> -->
                                <div class="navy-soft--text" v-html="explanation.text">
                                  <!-- nanti di comment lagi
                                  {{ explanation.text }} -->
                                </div>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </div>
                    </div>
                  </div>                  
                </div>
              </v-card>
            </v-row>
          </template>
          <template v-else-if="act.type == 'praktek_mandiri'">
            <ActPraktekMandiri
              :act="act"
              :id_topic="id_topic"
              :id_act="id_act"
              @getDetailClass="getDetailClass"
            >
            </ActPraktekMandiri>
          </template>
          <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
            <ActEvaluasiPraktekMandiri
              :act="act"
              :detail_class="detailClass"
              :id_topic="id_topic"
              :id_act="id_act"
              @getDetailClass="getDetailClass"
              @getActFalse="getActFalse"
            >
            </ActEvaluasiPraktekMandiri>
          </template>
          <template v-else-if="act.type == 'berdiskusi_daring'">
            <ActBerdiskusiDaring
              :act="act"
              :detail_class="detailClass"
              :id_topic="id_topic"
              :id_act="id_act"
              @getDetailClass="getDetailClass"
              @getActFalse="getActFalse"
            >
            </ActBerdiskusiDaring>
          </template>
          <template v-else-if="act.type == 'evaluasi'">
            <ActEvaluasi
              :act="act"
            >
            </ActEvaluasi>
          </template>
          <template v-else-if="act.type == 'membaca-e-book'">
            <ActMembacaEbook
             :act="act"
            >
            </ActMembacaEbook>
          </template>
          <template v-else>
            <v-row :no-gutters="$vuetify.breakpoint.smAndUp" class="bg-white">
              <v-col cols="12" class="pa-0">
                <v-card elevation="0">
                  <img style="width: 100%; object-fit: cover;" :src="cover.url" />
                  <img v-if="false" style="width: 100%; object-fit: cover;" class="locked-image" :src="cover.url" />
                  <LockedClass v-if="false"></LockedClass>
                </v-card>
              </v-col>
              <v-tabs id="" v-model="tabs">
                <v-tab class="text-capitalize">Deskripsi</v-tab>
                <v-tab class="text-capitalize">Ulasan</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tabs" class="setting col-md-12">
                <v-tab-item>
                  <v-card elevation="0" class="col-md-12">
                    <div class="fs-fix">
                      <!-- <h3 class="mb-2 pa-2">Deskripsi Pelatihan</h3> -->
                      <vue-show-more-text
                        :text="program.program_benefits"
                        :lines="10"
                        :more-text="more"
                        :less-text="less"
                        additional-content-css="font-size:0.875rem !important;"
                        additional-content-expanded-css="font-size:0.875rem !important;"
                        additional-anchor-css="font-size: 0.875rem !important;font-weight:bold;color:#f05326!important; text-align: center!important;"
                        @click="change"
                      />
                      <h3 class="pa-2">Bidang Studi</h3>
                      <p class="pa-2 fs-fix">
                        {{ this.studies.title }}
                      </p>

                      <h3 class="pa-2">Lembaga Pengajar</h3>
                      <div class="row">
                        <div class="col-md-3 align-self-center mx-auto text-center">
                          <img src="@/assets/images/logo-yec-new.png" style="width: 50%;" />
                        </div>
                        <div class="col-md-9">
                          <p class="pa-2 fs-fix">
                            Yureka Education Center (YEC) berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah
                            layanan jasa tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta
                            menjadi individu lebih baik. Tidak hanya sebatas menjadi jasa tour, namun juga menjadi
                            konsultan pendidikan dan media yang membantu menjadikan diri lebih baik dengan kemudahan
                            akses informasi dan program pelatihan terkait tes TOEFL, IELTS, Persiapan Kerja dan
                            Pembelajaran Bahasa Inggris Sehari-hari.
                          </p>
                        </div>
                      </div>

                      <h3 class="pa-2 mb-2">Fasilitator</h3>
                      <div class="row">
                        <div v-for="(item, index) in this.program.fasilitator" :key="'A' + index">
                          <div class="col-md-12 ma-auto">
                            <img
                              :src="item.picture.url"
                              class="mx-2 text-center"
                              style="width: 120px;height: 120px;border-radius: 50%;object-fit: cover;"
                            />
                          </div>
                        </div>
                        <div class="col-md-12" v-for="(item, index) in this.fasilitator" :key="'B' + index">
                          <p class="pa-2 mb-0 fs-fix" v-html="item.description">
                            <!-- {{ item.description }} -->
                          </p>
                        </div>
                      </div>
                    </div>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card elevation="0" class="col-md-12 pt-5" v-show="formUlasan">
                    <h3 class="ps-3">Ulasan Anda</h3>
                    <v-rating v-model="rating" background-color="#E0E6ED" color="primary" size="50"></v-rating>
                    <v-col cols="12" md="12" class="">
                      <v-textarea solo name="input-7-4" label="Berikan ulasan Anda" v-model="ulasan"></v-textarea>
                      <v-btn color="primary" class="btn-beli ms-auto rounded-pill" elevation="2" :loading="subSend" @click="send()">
                        Submit
                      </v-btn>
                    </v-col>
                    <!-- <v-divider></v-divider> -->
                  </v-card>
                  <div v-if="detailPublic.total_participants > 0" class="pt-5 px-4">
                    <p class="text-h5 mb-3">Peserta Bergabung <b style="color: #F05326;">{{detailPublic.total_participants}}</b></p>
                  </div>
                  <div v-if="totalData === 0" class="col-md-12 text-center mt-5">
                    <img src="@/assets/images/empty-review.png" width="70">
                    <p class="text-h6">Belum ada ulasan untuk kelas ini</p>
                  </div>
                  <div v-else class="pt-5 px-4">
                    <div class="rate col-md-12 text-center mb-3 mt-5">
                          <div class="row">
                            <v-card class="px-1 py-4 rounded-lg" outlined elevation="0">
                              <h2 class="font-weight-bold display-2">{{this.rate.rating}}</h2>
                              <v-rating
                                v-model="this.rate.rating"
                                color="#FFCC18"
                                background-color="#E0E6ED"
                                empty-icon="$ratingFull"
                                half-increments
                                hover
                                small
                                readonly                          
                              ></v-rating>
                              <p class="text-muted fs-14 font-weight-bold mb-0 color-disable">{{ this.rate.count }} Ulasan</p> 
                            </v-card>
                            <v-card class="pa-2 col-md-4 col-12" elevation="0" style="width: 150px;">
                              <v-list-item-group>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>5</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate5"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate5}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>4</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate4"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate4}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>3</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate3"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate3}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>2</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate2"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate2}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>
                                <v-list-item
                                  class="border-0"
                                  style="min-height: auto!important;border:none!important;"
                                >
                                  <v-list-item-icon class="mt-1 mb-0">
                                    <span><v-icon x-small color="#FFCC18">ri-star-s-fill</v-icon>1</span>
                                  </v-list-item-icon>

                                  <v-list-item-content class="py-0">
                                    <v-progress-linear
                                      v-model="persenRate1"                                      
                                      color="amber"
                                      height="8"
                                      rounded
                                    ></v-progress-linear>
                                  </v-list-item-content>
                                  <v-list-item-action class="my-0">
                                    <span class="color-disable">{{rate1}} ulasan </span>
                                  </v-list-item-action>
                                </v-list-item>

                              </v-list-item-group>
                            </v-card>                       
                          </div>
                    </div>
                    <!-- <div class="col-md-4"></div> -->
                    <v-col md="12">
                      <p class="text-h6 mb-0">Apa Kata Mereka</p>                      
                      <v-list three-line id="detail-kelas">
                        <template>
                          <v-list-item v-for="(item, index) in this.listReview" :key="'X' + index">
                            <v-list-item-avatar>
                              <img src="@/assets/images/no-pict.png" />
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>{{ item.member.fullname }}</v-list-item-title>
                              <div>
                                <v-icon color="yellow" small v-for="index in item.star" :key="'Y' + index"
                                  >mdi-star</v-icon
                                >
                              </div>
                              <v-list-item-subtitle style="display: block!important;">{{ item.review }}</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                      <v-btn
                        v-show="totalData > 2"
                        color="primary"
                        class="mx-auto text-center btn-beli"
                        text
                        @click="getReviewClass(-1)"
                      >
                        Tampilkan lebih banyak 
                      </v-btn>
                    </v-col>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </v-row>
          </template>
        </v-col>

        <v-slide-x-reverse-transition>
          <v-col v-if="isOpen" cols="12" sm="4" :style="responsiveStyle">
            <v-row v-if="!$vuetify.breakpoint.smAndUp" no-gutters>
              <v-btn class="pl-1 pr-2 mb-2" text color="primary" @click="showActivity = false">
                <v-icon class="mr-2">$prev</v-icon>
                Kembali
              </v-btn>
            </v-row>
            <p
              text
              height="32"
              class="pa-0 my-2 font-weight-bold text-capitalize"
              @click="
                act = {};
                act_active = null;
                topics_active = null;
              "
              style="cursor: pointer; text-decoration: none"
            >
              {{ program.title }}
            </p>
            <div class="rounded white py-4 mb-3">
              <div class="px-4 mb-2">
                <div class="d-flex align-center mb-2">
                  <v-progress-linear
                    class="mr-2"
                    color="primary"
                    background-color="grey lighten-3"
                    height="6"
                    rounded
                    :value="this.detailClass.results.progress"
                  ></v-progress-linear>
                  <div class="navy-soft--text">{{ this.detailClass.results.progress }}%</div>
                </div>
                <v-tabs id="" v-model="tab" background-color="transparent">
                  <v-tab class="text-capitalize">Aktivitas</v-tab>
                  <v-tab class="text-capitalize">Sertifikat</v-tab>
                </v-tabs>
                <!-- <div class="font-weight-bold">Aktifitas</div> -->
              </div>
              <v-tabs-items v-model="tab" class="setting transparent">
                <v-tab-item>
                  <div>
                    <v-expansion-panels
                      class="custom-panels overflow-auto"
                      style="max-height: 64vh"
                      v-model="topics_active"
                      focusable
                    >
                      <!-- :disabled="this.detailClass.results.progress ===100 && this.scorePost >= this.setMinim.minimum_value" -->
                      <v-expansion-panel class="rounded-0" v-for="(item, index) in topics" :key="index">
                        <v-expansion-panel-header class="px-4">
                          <div>
                            <div class="mb-1 font-weight-bold">{{ item.name }}</div>
                            <div class="text-body-2 color-navy-soft">{{ item.activities.length }} Aktivitas</div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="navy-soft pa-0">
                          <v-list dense class="col-12 pa-0">
                            <v-list-item-group v-model="act_active">
                              <v-divider></v-divider>
                              <v-list-item
                                v-for="(act, i) in item.activities"
                                :key="i"
                                class="grey lighten-5 d-block"
                                @click="
                                  getAct(act.id, item.id);
                                  getActFalse(item.id, act.id);
                                  showActivity = false;
                                  timer_stop();
                                  totalSeconds = 0;
                                "
                                :disabled="act.is_lock == true"
                              >
                                <v-list-item-content>
                                  <div class="d-flex my-1">
                                    <v-icon
                                      v-if="act.is_complete === true"
                                      class="mr-1 mt-0"
                                      color="green darken-3"
                                      small
                                      >ri-checkbox-circle-fill</v-icon
                                    >
                                    <div style="width: 2rem">{{ i + 1 }}.</div>
                                    <div>
                                      <div class="mb-1 font-weight-medium">
                                        {{ act.title }}
                                      </div>
                                      <div class="color-navy-soft text-body-2 d-flex">
                                        <template v-if="act.type == 'menonton_video'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-film-line</v-icon>
                                            Menonton Video
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            00:{{ act.settings.estimated_duration | minute }}:00
                                          </span>
                                        </template>
                                        <template
                                          v-else-if="
                                            act.type == 'pre_test' || act.type == 'post_test' || act.type == 'quiz'
                                          ">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                            Mengerjakan Soal
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'praktek_mandiri'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-download-line</v-icon>
                                            Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'evaluasi_praktek_mandiri'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-upload-line</v-icon>
                                            Evaluasi Praktek Mandiri
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'berdiskusi_daring'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-survey-line</v-icon>
                                            Berdiskusi Daring
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'evaluasi'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-edit-box-line</v-icon>
                                            Evaluasi
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                        <template v-else-if="act.type == 'membaca-e-book'">
                                          <span class="d-flex mr-2">
                                            <v-icon class="navy-soft--text mr-1" small>ri-file-list-line</v-icon>
                                            Membaca E-Book
                                          </span>
                                          <span class="d-flex">
                                            <v-icon class="navy-soft--text mr-1" small>ri-time-line</v-icon>
                                            <!-- 00:{{ act.settings.estimated_duration }}:00 -->
                                            {{ dateTime(act.settings.estimated_duration)}}
                                          </span>
                                        </template>
                                      </div>
                                    </div>
                                  </div>
                                </v-list-item-content>
                                <v-divider
                                  style="
                                  position: absolute;
                                  left: 0;
                                  right: 0;
                                  bottom: 0;
                                "
                                ></v-divider>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <v-divider class="mb-2"></v-divider>
                  <div class="col-md-10 mx-auto">
                    <div v-if="false">
                      <v-btn
                        color="primary"
                        rounded
                        class="mx-auto text-center btn-beli mb-2"
                        block
                        elevation="2"
                        :disabled="this.detailClass.results.progress !== 100"
                        :to="{ name: 'certificate-participant', params: { id: this.detailClass.license } }"
                        target="_blank"
                      > 
                        Sertifikat Penyelesaian
                      </v-btn>
                    </div>
                    <div>
                      <a target="_blank" :href="detailClass.results.certificates.graduation.url">
                        <v-btn
                          color="primary"
                          rounded
                          class="mx-auto text-center btn-beli"
                          block
                          elevation="2"
                          :disabled="!isDisable"
                        >
                          Sertifikat Kompetensi 
                        </v-btn>
                      </a>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs-items>
            </div>
          </v-col>
        </v-slide-x-reverse-transition>
      </v-row>
    </v-container>
    <v-snackbar v-model="notification.state" top color="primary" outlined>
      {{ notification.message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" icon v-bind="attrs" @click="notification.state = false">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      v-model="showScan"
      persistent
      max-width="580px"
      content-class="rounded-xl"
    >
      <v-card class="pa-4">
        <v-card-title class="pt-0 px-0 d-flex justify-end">
          <v-btn
            fab
            color="white"
            @click="showScan = false"
            small>
            <v-icon color="#90A4AE">ri-close-circle-line</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0 text-center">
          <span class="text-h5">QR Code Kelas </span>
          <div class="col-md-8 mx-auto pt-0">
            <p class="mb-0 text-body-1 pt-3">{{program.title}}</p>
          </div>
          <vue-qrcode :value="$route.params.id" :options="{ width: 200 }"></vue-qrcode>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Berikan Ulasan Program</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <p class="mb-0 text-body-1 pt-3">Selamat kamu telah menyelesaikan semua aktifitas!</p>
          <v-card elevation="0" class="px-0">
            <v-rating v-model="rating" required background-color="#E0E6ED" color="primary" size="50"></v-rating>
            <v-col cols="12" md="12" class="mb-0 px-0">
              <v-textarea solo name="input-7-4" label="Berikan ulasan Anda" v-model="ulasan" required hide-details="auto"></v-textarea>
            </v-col>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="dialog = false"
            small
            class="mb-3"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"            
            :loading="subSend" @click="send()"
            small
            class="mb-3 me-2"
            :disabled="ulasan === '' || rating === 0"
          >
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="activity_unlock"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Perhatian</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <p class="mb-0 text-body-1 pt-3">Mohon maaf tidak bisa mengakses aktifitas ini. Silahkan menyelesaikan aktifitas sebelumnya.</p>          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"            
            :loading="subSend" 
            @click="reloadPage()"
            small
            class="mb-3 me-2"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_peringatan"
      persistent
      max-width="600px"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Anda Terindikasi mempercepat video
          
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog_peringatan = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog_support"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
          class="font-weight-bold text-h5"
        >Perhatian</v-toolbar>
        <v-card-text class="pb-0">
          <div class="text-body-1 font-weight-medium pt-4">Silahkan gunakan browser lain untuk mengakses aktifitas ini.</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            @click="dialog_support = false"
          >Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script src="http://www.youtube.com/player_api"></script>
<script>
import videojs from 'video.js';
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
// import pdf from 'vue-pdf'
// import VideoPlayer from "@/components/VideoPlayer.vue";
import AudioPlayer from "@/views/_inc/media/AudioPlayer.vue";

import vueShowMoreText from "vue-show-more-text";
import VueYoutube from "vue-youtube";
import moment from "moment";
import { mapGetters } from "vuex";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import LockedClass from "./components/LockedClass.vue"

import ActPraktekMandiri from "./act/ActPraktekMandiri";
import ActEvaluasiPraktekMandiri from "./act/ActEvaluasiPraktekMandiri";
import ActBerdiskusiDaring from "./act/ActBerdiskusiDaring";
import ActEvaluasi from "./act/ActEvaluasi";
import ActMembacaEbook from "./act/ActMembacaEbook";

document.oncontextmenu = function() {return false;};
export default {
  name: "detail-class",
  components: {
    vueShowMoreText,
    AudioPlayer,
    // VideoPlayer,
    // pdf,
    VuePdfApp,
    VueQrcode,
    LockedClass,

    ActPraktekMandiri,
    ActEvaluasiPraktekMandiri,
    ActBerdiskusiDaring,
    ActEvaluasi,
    ActMembacaEbook,
  },
  filters: {
    minute: (val) => String(val).padStart(2, 0),
    minutesAndSeconds(value) {
      var hours = `${Math.floor(value / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(value / 60) % 60}`.padStart(2, 0);
      var seconds = `${value % 60}`.padStart(2, 0);
      return `${hours} : ${minutes} : ${seconds}`;
    },
  },
  data: () => {
    return {
      config: {
        toolbar: {
          toolbarViewerLeft: {
            findbar: true,
            previous: true,
            next: true,
            pageNumber: true,
          },
          toolbarViewerRight: {
            presentationMode: true,
            openFile: false,
            print: false,
            download: false,
            viewBookmark: false,
          },
          toolbarViewerMiddle: {
            zoomOut: true,
            zoomIn: true,
            scaleSelectContainer: true,
          },
        },
        errorWrapper: true,
      },
      dialog_peringatan:false,
      video_url:'',
      videoOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: null,
            // type: "application/x-mpegURL",
            type: "video/mp4",
          },
        ],
      },
      headers_nilai: [
        { text: "Jenis Test", value: "name", align: "start", sortable: false },
        { text: "Nilai Minimal", value: "min_score", align: "center", sortable: false },
        { text: "Hasil Nilai Test", value: "score", align: "center", sortable: false },
        { text: "Keterangan", value: "result_score", align: "center", sortable: false },
      ],
      dialog:false,
      tab: null,
      tabs: null,
      loadContent: false,
      content: false,
      detailClass: {},
      detailPublic: {},
      notification: {},
      act: {},
      cover: {},
      fasilitator: {},
      studies: {},
      id_act: "",
      id_topic: "",
      isLoading: false,
      // result: null,
      review: null,
      showActivity: null,
      activity_loading: null,
      act_active: null,
      topics_active: null,

      image: null,
      file: "",
      loadUpload: false,

      resultNilai: false,
      showQues: true,
      showExplanation: false,

      resultNilaiFalse: false,
      showQuesFalse: true,
      showExplanationFalse: false,

      // pre
      showExplanationPre: false,
      showExplanationPreFalse: false,

      //post
      resultNilaiPost: false,
      showQuesPost: true,
      showExplanationPost: false,

      resultNilaiPostFalse: false,
      showQuesPostFalse: true,
      showExplanationPostFalse: false,

      //exam
      resultNilaiExam: false,
      showQuesExam: true,
      showExplanationExam: false,

      resultNilaiExamFalse: false,
      showQuesExamFalse: true,
      showExplanationExamFalse: false,

      more: "Lihat Selengkapnya",
      less: "Sembunyikan",

      playerVars: {
        controls: 0,
      },
      player: {},
      play:'',
      psr: "peserta",
      kom: "kompetensi",
      typeCertif: "",
      listRandom: null,
      randomQuiz: null,

      subSend: false,

      rating: 0,
      ulasan: "",
      totalData:null,

      //inisialisasi rate
      rate5:null,
      rate4:null,
      rate3:null,
      rate2:null,
      rate1:null,
      persenRate1:null,
      persenRate2:null,
      persenRate3:null,
      persenRate4:null,
      persenRate5:null,

      scorePost: "",
      setMinim: {},
      certifP: {},
      certifL: {},
      generate:{},
      generateP:{},
      rate: {},
      listReview: {},
      id_pro: "",
      timeUpdate: 0,

      formUlasan: false,
      loadCertif:false,

      activity_id_active:'',
      topic_id_active:'',

      timer: {},
      totalSeconds: null,
      interval: {},
      timerDialog: "",

      showScan:false,
      valueCode:'testing',
      size: 300,
      colorScan:{ dark: '#000000ff', light: '#ffffffff' },
      quality:0.92,

      dialog_support:false,
      showError:false,

      show_direction: false,
      questionAnswer: [],

      activity_unlock:false,
      player: null,
    };
  },
  created() {
    this.getDetailClass();
    setTimeout(() => {
      this.getFormReview(this.detailClass.license, this.id_pro.id);
    }, 2000);

    // window.addEventListener("keydown", function(e) {
    //   if(["Space","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"].indexOf(e.code) > -1) {
    //       e.preventDefault();
    //   }
    // }, false);
  },
  mounted() {
    
  },
  computed: {
    ...mapGetters(["user"]),
    isOpen() {
      return this.$vuetify.breakpoint.smAndUp || this.showActivity;
    },
    isDisable() {
      return this.detailClass.is_complete && Object.keys(this.detailClass.results.certificates.graduation).length > 0 && this.detailClass.results.certificates.graduation.url.length > 0
      // return this.detailClass.results.progress !== 100 || this.scorePost < this.setMinim.minimum_value;
    },
    isRequired(){
      return (this.currentQuestion.answer === null || this.currentQuestion.answer === undefined) || this.act.current === this.act.settings.number_questions_package  ;
      // return this.act.current >= this.act.settings.number_questions_package || this.currentQuestion.answer == null;
    },
    isRequiredExam(){
      return (this.currentQuestionExam.answer === null || this.currentQuestionExam.answer === undefined) || this.act.current === this.act.settings.number_questions_package  ;
      // return this.act.current >= this.act.settings.number_questions_package || this.currentQuestion.answer == null;
    },
    result_exam() {
      let graduated = true;
      // let a= [
      //   {
      //     id: "62b17791e152f959361a2041",
      //     min_score: 80,
      //     name: "TKP",
      //     score: 80,
      //   },
      //   {
      //     id: "62b17791e152f959361a2042",
      //     min_score: 10,
      //     name: "TKP",
      //     score: 1,
      //   },
      //   {
      //     id: "62b17791e152f959361a2043",
      //     min_score: 10,
      //     name: "TKP",
      //     score: 25,
      //   }
      // ]
      this.act.section.forEach((item) => {
        if (item.score < item.min_score) {
          graduated = false
        }
      })
      return graduated

    },
    responsiveStyle() {
      return this.$vuetify.breakpoint.smAndUp
        ? ""
        : {
            position: "absolute",
            backgroundColor: "#eff2f7",
            height: "100vh",
            overflow: "auto",
          };
    },
    program() {
      return this.detailClass.program || {};
    },
    topics() {
      return this.detailClass.topics || [];
    },
    questions() {
      try {
        return this.act.list_question.reduce((cur, val) => [...cur, ...val.list_question], []);        
      } catch (error) {
        return [];
      }
    },
    questionsExam() {
      try {
        let result = []
        // .reduce((cur, val) => [...cur, ...val.list_question], [])
        this.act.list_question_exam.forEach((value) => {
          value.groups.forEach((val) => {
            result = [...result, ...val.list_question]
          })
        })
        // console.log('result',result);
        return result;
      } catch (error) {
        return [];
      }
    },
    questionsExplan() {
      try {
        return this.act.list_question_explan;
      } catch (error) {
        return [];
      }
    },
    currentQuestion() {
      try {
        const index = this.act.current - 1;
        return this.questions[index];
      } catch (error) {
        return {};
      }
    },
    optionQuestion() {
      try {
        const index = this.act.current - 1;
        return this.questions[index].test.options;
      } catch (error) {
        return {};
      }
    },
    currentQuestionExam() {
      try {
        const index = this.act.current - 1;
        return this.questionsExam[index];
        // return this.questionsExam[index].question;
      } catch (error) {
        return {};
      }
    },
    optionQuestionExam() {
      try {
        const index = this.act.current - 1;
        return this.questionsExam[index].options;
      } catch (error) {
        return {};
      }
    },
    currentQuestionExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].test.question;        
      } catch (error) {
        return {};
      }
    },
    optionQuestionExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].test.options;        
      } catch (error) {
        return {};
      }
    },
    answerTextExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].answer[0];        
      } catch (error) {
        return {};
      }
    },
    correctTextExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].correct_answer[0];        
      } catch (error) {
        return {};
      }
    },
    answerExplan() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].is_answered;        
      } catch (error) {
        return {};
      }
    },
    correctAnswer() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].is_correct;        
      } catch (error) {
        return {};
      }
    },
    explanation() {
      try {
        const index = this.act.current - 1;
        return this.questionsExplan[index].test.explanation.correct;        
      } catch (error) {
        return {};
      }
    },
  },
  methods: {

    handleLoaded(instance) {
      console.log("PSPDFKit has loaded: ", instance);
      // Do something.
    },
    ended(act) {
      // this.getUnlock(this.topics[this.topics_active].id, this.act.id);
      if(act == false){
        this.getUnlock(this.id_topic, this.id_act);
      }
    },
    change(showAll) {
      console.log(showAll);
    },
    getDetailClass() {
      this.loadContent = true;
      this.content = false;
      this.axios.get(`/users/v1/myprogram/detail/${this.$route.params.id}`).then((response) => {
        this.content = true;
        this.loadContent = false;
        let res = response.data.data;
        this.detailClass = res;
        this.fasilitator = res.program.fasilitator;
        this.id_pro = res.program;
        this.cover = res.program.program_information.cover;
        this.studies = res.program.learning_targets.studies;
        this.scorePost = res.results.scores.post_test.final_score;
        this.setMinim = res.results.scores.post_test.set_minimum_value;
        this.certifP = res.results.certificates.participant;
        this.certifL = res.results.certificates.graduation;

        if (res.results.progress === 100) {
          this.detailReview(res.license, this.id_pro.id);
        }
        this.getReviewClass(2);
        this.getDetailPublic(this.id_pro.id);
      });
    },
    getDetailPublic(id) {
      this.axios
        .get(`/users/v1/public/program/detail/${id}`)
        .then((response) => {
          let res = response.data.data;
          this.detailPublic = res;          
        });
    },
    detailReview(voucher, id) {
      const data = {
        license: voucher,
        program_id: id,
      };
      this.axios
        .post(`/program_review/v1/detail`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          // this.dialog=false;
          this.formUlasan=false;
        })
        .catch((error) => {
          // console.error(error.response);
          if (this.detailClass.results.progress != 100) {
            // this.dialog = false;
            this.formUlasan=false;
          }
          else{
            // this.dialog=true;
            this.formUlasan=true;
          }
        });
    },
    getFormReview(voucher, id) {
      const data = {
        license: voucher,
        program_id: id,
      };
      this.axios
        .post(`/program_review/v1/detail`, data, {
          headers: { "Content-Type": "application/json" },
        })
        .then(() => {
          this.dialog=false;
        })
        .catch((error) => {
          if (this.detailClass.results.progress != 100) {
            this.dialog = false;
          }
          else{
            this.dialog=true;
          }
        });
    },
    getReviewClass(value) {
      this.axios
        .get(`/program_review/v1/list?program_id=${this.id_pro.id}&sort=created_at&sort_type=desc&is_publish=1&limit=${value}`, {
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY },
        })
        .then((response) => {
          this.content = true;
          this.loadContent = false;
          let res = response.data.data;
          this.listReview = res.list;
          this.rate = res.rating;
          this.totalData=res.total_data;
          this.rate5=res.stars[0].star5;
          this.rate4=res.stars[0].star4;
          this.rate3=res.stars[0].star3;
          this.rate2=res.stars[0].star2;
          this.rate1=res.stars[0].star1;
            // console.log(this.rate5)
          this.persenRate5=parseInt( Math.round( ( this.rate5/this.rate.count ) * 100 ) );
          this.persenRate4=parseInt( Math.round( ( this.rate4/this.rate.count ) * 100 ) );
          this.persenRate3=parseInt( Math.round( ( this.rate3/this.rate.count ) * 100 ) );
          this.persenRate2=parseInt( Math.round( ( this.rate2/this.rate.count ) * 100 ) );
          this.persenRate1=parseInt( Math.round( ( this.rate1/this.rate.count ) * 100 ) );
        });
    },
    getAct(act, id) {
      this.id_topic = id;
      this.id_act = act;
      this.isLoading = false;
      
      this.axios
        .get(`/users/v1/myprogram/detail_activity/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
        .then(async (response) => {
          let res = response.data.data;
          this.act = res;
          this.getDetailClass();
          if (this.act.type == "pre_test") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            }else{
              this.activity_unlock=true
            }
              if (this.act.is_complete == true || this.act.repeat_test > 0) {
                this.resultNilai = this.resultNilaiFalse = true;
                this.showQues = this.showQuesFalse = false;
              } else {
                this.repeat();
                this.repeatFalse(this.act.type,this.act.settings);
              }
              this.$set(this.act, "current", 1);
              this.activity_loading=true;
              await this.axios
                .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
                .then((res) => res.data)
                .then(async (res) => {                
                  if(this.act.settings.is_random_question===true){
                    let shuffle = [...res.data];
                    // console.log('list', shuffle)
                    let first,
                      second,
                      temp,
                      count = shuffle.length;
                    for (let i = 0; i < 10; i++) {
                      first = Math.floor(Math.random() * count);
                      second = Math.floor(Math.random() * count);
                      temp = shuffle[first];
                      shuffle[first] = shuffle[second];
                      shuffle[second] = temp;
                    }
                    this.listRandom = shuffle;
                    this.$set(this.act, "list_question", this.listRandom);
                    await this.setQuestion();
                    if (this.act.settings.is_show_timer === true && this.act.is_complete === false) {
                      this.timer_start(this.act.type,this.act.settings);
                    }
                  }else{
                    this.$set(this.act, "list_question", res.data);
                    await this.setQuestion();
                    if (this.act.settings.is_show_timer === true && this.act.is_complete === false) {
                      this.timer_start(this.act.type,this.act.settings);
                    }
                  }
                });
              return;
            
          } else if (this.act.type == "post_test") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            }else{
              this.activity_unlock=true
            }
              if (this.act.is_complete == true || this.act.repeat_test > 0) {
                this.resultNilaiPost = this.resultNilaiPostFalse = true;
                this.showQuesPost = this.showQuesPostFalse = false;
              } else {
                this.repeatPost();
                this.repeatPostFalse(this.act.type,this.act.settings);
              }
              this.$set(this.act, "current", 1);
              this.activity_loading=true;
              await this.axios
                .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
                .then((res) => res.data)
                .then(async (res) => {                
                  if(this.act.settings.is_random_question===true){
                    let shuffle = [...res.data];
                    // console.log('list', shuffle)
                    let first,
                      second,
                      temp,
                      count = shuffle.length;
                    for (let i = 0; i < 10; i++) {
                      first = Math.floor(Math.random() * count);
                      second = Math.floor(Math.random() * count);
                      temp = shuffle[first];
                      shuffle[first] = shuffle[second];
                      shuffle[second] = temp;
                    }
                    this.listRandom = shuffle;
                    this.$set(this.act, "list_question", this.listRandom);
                    await this.setQuestion();
                    if (this.act.settings.is_show_timer === true && this.act.is_complete === false) {
                      this.timer_start(this.act.type,this.act.settings);
                    }
                  }else{
                    this.$set(this.act, "list_question", res.data);
                    await this.setQuestion();
                    if (this.act.settings.is_show_timer === true && this.act.is_complete === false) {
                      this.timer_start(this.act.type,this.act.settings);
                    }                  
                  }
                });
              return;
          } else if (this.act.type == "exam") {
            if (this.act.is_lock === false){
              this.activity_unlock=false;
            } else {
              this.activity_unlock=true
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0) {
              this.resultNilaiExam = this.resultNilaiExamFalse = true;
              this.showQuesExam = this.showQuesExamFalse = false;
            } 

            this.$set(this.act, "current", 1);
            this.activity_loading=true;
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question_exam", this.listRandom);
                  await this.setQuestionExam();
                  if (this.act.settings.is_show_timer === true && this.act.score === 0 ) {
                      this.timer_start(this.act.type,this.act.settings);
                    }
                }else{
                  this.$set(this.act, "list_question_exam", res.data);
                  await this.setQuestionExam(); 
                  if (this.act.settings.is_show_timer === true && this.act.score === 0) {
                    this.timer_start(this.act.type,this.act.settings);
                  }
                }
              });
            return;
          } else if (this.act.type == "quiz") {
            if (this.act.is_lock === false){
              this.activity_unlock=false;
            } else {
              this.activity_unlock=true
            }

              if (this.act.is_complete == true || this.act.repeat_test > 0) {
                this.resultNilai = this.resultNilaiFalse = true;
                this.showQues = this.showQuesFalse = false;
              } else {
                this.repeat();
                this.repeatFalse(this.act.type,this.act.settings);
              }
              this.showExplanation=this.showExplanationFalse=false;
              this.$set(this.act, "current", 1);
              this.activity_loading=true;
              await this.axios
                .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
                .then((res) => res.data)
                .then(async (res) => {
                  if(this.act.settings.is_random_question===true){
                    let shuffle = [...res.data];
                    let first,
                      second,
                      temp,
                      count = shuffle.length;
                    for (let i = 0; i < 10; i++) {
                      first = Math.floor(Math.random() * count);
                      second = Math.floor(Math.random() * count);
                      temp = shuffle[first];
                      shuffle[first] = shuffle[second];
                      shuffle[second] = temp;
                    }
                    this.randomQuiz = shuffle;
                    this.$set(this.act, "list_question", this.randomQuiz);
                    await this.setQuestion();
                    if (this.act.settings.is_show_timer === true && this.act.is_complete === false) {
                      this.timer_start(this.act.type,this.act.settings);
                    }
                  } else {
                    this.$set(this.act, "list_question", res.data);
                    await this.setQuestion();
                    if (this.act.settings.is_show_timer === true && this.act.is_complete === false) {
                      this.timer_start(this.act.type,this.act.settings);
                    }
                  }
                });
              return;
            // }else{
            //   this.activity_unlock=true;
            // }
          } else if (this.act.type == "evaluasi") {
            if (this.act.is_lock === false) {
              this.activity_unlock=false
              // this.getUnlockEvaluasi(this.topics[this.topics_active].id, this.act.id)
              if (this.act.is_complete == false) {
              this.getUnlockEvaluasi(this.id_topic, this.id_act) }
            }else {
              this.activity_unlock=true;
            }
            // this.changeStatus();
          } 
          else if (this.act.type == "membaca-e-book") {
            if(this.act.is_lock === false) {
              this.activity_unlock=false;
              if (this.act.is_complete == false) {
                this.changeStatus();
              }
            } else {
              this.activity_unlock=true;
            }
          }
          else if (this.act.type == "menonton_video") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
              // this.changeStatus(); 
                let vid2 = document.getElementById("videoElement");

              if (navigator.userAgent.indexOf(' UCBrowser/') >= 0) {
                this.showVideo=false;
                this.dialog_support=true;
                this.showError=true;
                let vid = document.getElementById("videoElement");

                // $('*').off('keyup keydown keypress');
                vid.autoplay = false;
                vid.load();
              }else{
                this.showVideo=true;
                this.showError=false;
                this.video_url=this.act.theory.file.url;

                this.$set(this.videoOptions.sources[0], "src", this.act.theory.file.url);                                
                this.updatePositionState();            
                let $vm = this;
                document.addEventListener('keydown', function(e) {
                   switch (e.keyCode) {
                    case 37:
                        var vid2 = document.getElementById("videoElement");
                        
                        // alert('left');

                        // $vm.dialog_peringatan=true;
                        // console.log("time current",$vm.dialog_peringatan);
                        vid2.pause();
                        vid2.currentTime=0;
                        break;
                    case 38:
                       alert('up');
                        break;
                    case 39:
                        var vid2 = document.getElementById("videoElement");
                        
                        // alert('right');
                        // $vm.dialog_peringatan=true;
                        // console.log("time current",$vm.dialog_peringatan);
                        vid2.pause();
                        vid2.currentTime=0;
                        break;
                    case 40:
                        alert('down');
                        break;
                  }
                });               
                this.video_url=this.act.theory.file.url;
                this.$nextTick(function() {
                  const video = document.querySelector('#videoElement');                    
                  var supposedCurrentTime = 0;
                  video.addEventListener('timeupdate', function() {
                    if (!video.seeking) {
                          supposedCurrentTime = video.currentTime;
                    }
                  });
                  // prevent user from seeking
                  video.addEventListener('seeking', function() {
                    // guard agains infinite recursion:
                    // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
                    var delta = video.currentTime - supposedCurrentTime;
                    if (Math.abs(delta) > 0.01) {
                      // console.log("Seeking is disabled");
                      video.currentTime = supposedCurrentTime;
                    }
                  });
                  // delete the following event handler if rewind is not required
                  // video.addEventListener('ended', function() {
                  //   // reset state in order to allow for rewind
                  //     supposedCurrentTime = 0;
                  // });
                  video.load();
                });
                vid2.autoplay = true;
                vid2.load();

              }
            } else {
              this.activity_unlock=true;
            }

            // this.getTest();
            
            // this.$refs.videoPlayer.$forceUpdate();
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    }, 
    updatePositionState() {
      if ('mediaSession' in navigator) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: 'BAKAT',
          artist: '',
          album: '',
          artwork: [
            { src: 'https://dummyimage.com/96x96',   sizes: '96x96',   type: 'image/png' },
            { src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png' },
            { src: 'https://dummyimage.com/192x192', sizes: '192x192', type: 'image/png' },
            { src: 'https://dummyimage.com/256x256', sizes: '256x256', type: 'image/png' },
            { src: 'https://dummyimage.com/384x384', sizes: '384x384', type: 'image/png' },
            { src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png' },
          ]
        });
        navigator.mediaSession.setActionHandler('seekto', function() { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('seekbackward', function() { /* Code excerpted. */ });
        navigator.mediaSession.setActionHandler('seekforward', function() { /* Code excerpted. */ });
        
      }
    },   
    getActFalse(topic, act) {
      this.id_topic = topic;
      this.id_act = act;
      // this.isLoading = true;
      this.axios
        .get(`/users/v1/myprogram/detail_activity/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
        .then(async (response) => {
          let res = response.data.data;
          this.act = res;
          // this.getDetailClass();

          if (this.act.type == "pre_test") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            }else{
              this.activity_unlock=true
            }
            
            if (this.act.is_complete == true || this.act.repeat_test > 0) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            } 
            // else {
            //   this.repeat();
            //   this.repeatFalse();
            // }            
            this.$set(this.act, "current", 1);
            this.activity_loading=true;
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();                  
                }
              });
            return;
          } else if (this.act.type == "post_test") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            }else{
              this.activity_unlock=true
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0) {
              this.resultNilaiPost = this.resultNilaiPostFalse = true;
              this.showQuesPost = this.showQuesPostFalse = false;
            } 
            // else {
            //   this.repeatPost();
            //   this.repeatPostFalse(this.act.type,this.act.settings);
            // }           
            this.$set(this.act, "current", 1);
            this.activity_loading=true;            
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {                
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question", this.listRandom);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();                  
                }
              });
            return;
          } else if (this.act.type == "exam") {
            // console.log('exam', this.act);
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            }else{
              this.activity_unlock=true
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0) {
              this.resultNilaiExam = this.resultNilaiExamFalse = true;
              this.showQuesExam = this.showQuesExamFalse = false;
            } 

            this.$set(this.act, "current", 1);
            this.activity_loading=true;
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {   

                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  // console.log('list', shuffle)
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.listRandom = shuffle;
                  this.$set(this.act, "list_question_exam", this.listRandom);
                  // console.log('this.act.list_question_exam', this.act);
                  await this.setQuestionExam();
                }else{
                  this.$set(this.act, "list_question_exam", res.data);
                  // console.log('this.act.list_question_exam false', this.act);
                  await this.setQuestionExam();                  
                }
              });
            return;
          } else if (this.act.type == "quiz") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
            }else{
              this.activity_unlock=true
            }
            if (this.act.is_complete == true || this.act.repeat_test > 0) {
              this.resultNilai = this.resultNilaiFalse = true;
              this.showQues = this.showQuesFalse = false;
            }
            this.showExplanation=this.showExplanationFalse=false;
            this.$set(this.act, "current", 1);
            this.activity_loading=true;
            await this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                if(this.act.settings.is_random_question===true){
                  let shuffle = [...res.data];
                  let first,
                    second,
                    temp,
                    count = shuffle.length;
                  for (let i = 0; i < 10; i++) {
                    first = Math.floor(Math.random() * count);
                    second = Math.floor(Math.random() * count);
                    temp = shuffle[first];
                    shuffle[first] = shuffle[second];
                    shuffle[second] = temp;
                  }
                  this.randomQuiz = shuffle;
                  this.$set(this.act, "list_question", this.randomQuiz);
                  await this.setQuestion();
                }else{
                  this.$set(this.act, "list_question", res.data);
                  await this.setQuestion();
                }
              });
            return;
          } else if (this.act.type == "evaluasi") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
              if(this.act.is_complete == false){
              this.getUnlockEvaluasi(this.id_topic, this.id_act)}
            } else {
              this.activity_unlock=true
            }
          }
          else if (this.act.type == "membaca-e-book") {
            if(this.act.is_lock === false){
              this.activity_unlock=false;
              if(this.act.is_complete == false){
                this.changeStatus();
              }
            }else{
              this.activity_unlock=true
            }
          }
          // else if (this.act.type == "menonton_video") {
          //   if(this.act.is_lock === false){
          //     this.activity_unlock=false;              
          //     // this.changeStatus(); 
          //       let vid2 = document.getElementById("videoElement");

          //     if (navigator.userAgent.indexOf(' UCBrowser/') >= 0) {
          //       this.showVideo=false;
          //       this.dialog_support=true;
          //       this.showError=true;              
          //       let vid = document.getElementById("videoElement");

          //       // $('*').off('keyup keydown keypress');
          //       vid.autoplay = false;
          //       vid.load();
          //     }else{
          //       this.showVideo=true;
          //       this.showError=false;
          //       this.video_url=this.act.theory.file.url;

          //       this.$set(this.videoOptions.sources[0], "src", this.act.theory.file.url);                                
          //       this.updatePositionState();            
          //       let $vm = this;
          //       document.addEventListener('keydown', function(e) {
          //          switch (e.keyCode) {
          //           case 37:
          //               var vid2 = document.getElementById("videoElement");
                        
          //               // alert('left');

          //               // $vm.dialog_peringatan=true;
          //               // console.log("time current",$vm.dialog_peringatan);
          //               vid2.pause();
          //               vid2.currentTime=0;
          //               break;
          //           case 38:
          //              alert('up');
          //               break;
          //           case 39:
          //               var vid2 = document.getElementById("videoElement");
                        
          //               // alert('right');
          //               // $vm.dialog_peringatan=true;
          //               // console.log("time current",$vm.dialog_peringatan);
          //               vid2.pause();
          //               vid2.currentTime=0;
          //               break;
          //           case 40:
          //               alert('down');
          //               break;
          //         }
          //       });               
          //       this.video_url=this.act.theory.file.url;
          //       this.$nextTick(function() {

          //         const video = document.querySelector('#videoElement');
          //         console.log(video)
                    

          //         var supposedCurrentTime = 0;
          //         video.addEventListener('timeupdate', function() {
          //           if (!video.seeking) {
          //                 supposedCurrentTime = video.currentTime;
          //           }
          //         });
          //         // prevent user from seeking
          //         video.addEventListener('seeking', function() {
          //           // guard agains infinite recursion:
          //           // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
          //           var delta = video.currentTime - supposedCurrentTime;
          //           if (Math.abs(delta) > 0.01) {
          //             // console.log("Seeking is disabled");
          //             video.currentTime = supposedCurrentTime;
          //           }
          //         });
          //         // delete the following event handler if rewind is not required
          //         // video.addEventListener('ended', function() {
          //         //   // reset state in order to allow for rewind
          //         //     supposedCurrentTime = 0;
          //         // });
          //         video.load();
          //       });
          //       vid2.autoplay = true;
          //       vid2.load();

          //     }
          //   } else {
          //     this.activity_unlock=true;
          //   }

          //   // this.getTest();
            
          //   // this.$refs.videoPlayer.$forceUpdate();
          // }          
          this.isLoading = false;
        })
        .catch((error) => {
          console.error(error);
          this.isLoading = false;
        });
    },
    getExplan(answer){
      this.activity_loading=true;
      this.$set(this.act, "current", 1);
      if(answer == true){
        this.axios
              .get(`/users/v1/myprogram/answer_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                  this.$set(this.act, "list_question_explan", res.data);
                  this.setQuestionExplan(answer);

              })
              .catch((error) => {
              let message = error.response ? error.response.data.message : error.message;
              this.notification = {
                state: true,
                message,
              };
              });
      }else{
        this.axios
              .get(`/users/v1/myprogram/question_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                  this.$set(this.act, "list_question_explan", res.data);
                  this.setQuestionExplan(answer);                                  
              })
              .catch((error) => {
              let message = error.response ? error.response.data.message : error.message;
              this.notification = {
                state: true,
                message,
              };
              });
      }
    },
    getExplanExam(){
      this.activity_loading=true;
      this.$set(this.act, "current", 1);
        this.axios
              .get(`/users/v1/myprogram/answer_list/${this.$route.params.id}/${this.id_topic}/${this.id_act}`)
              .then((res) => res.data)
              .then(async (res) => {
                  this.$set(this.act, "list_question_explan", res.data);
                  this.setQuestionExplanExam();

              })
              .catch((error) => {
              let message = error.response ? error.response.data.message : error.message;
              this.notification = {
                state: true,
                message,
              };
              });      
    },
    setQuestionExplan(answer) {
      this.activity_loading=true;      
      this.result = false;
      if (answer == true) { 
        console.log(answer)       
        let question_count = 0;
        const question_group = this.act.list_question_explan.map((val) => {
          const a = val.question_id;
          return a ;
        });
        const data = {
          id:question_group
        }
        this.axios.post(`/bank/v1/question/public/detail_multiple`,data,{
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
        })
          .then((res) => res.data)
          .then((res) => {
            this.activity_loading=false;
            res.data.forEach((question, index) => {
              this.$set(this.act.list_question_explan[index], 'test', question);
              if (++question_count == this.act.list_question_explan.length) {
                this.isLoading = false;
              }
            })

          });
      }else{
        const total_question = this.act.list_question_explan.reduce((cur, val) => {
          return cur + val.list_question.length;
        }, 0);
        let question_count = 0;
        const question_group = this.act.list_question_explan.map((val) => {
          const a = val.list_question[0].question_id;
          return a ;
        });
        const data = {
          id:question_group
        }
        this.axios.post(`/bank/v1/question/public/detail_multiple`,data,{
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
        })
          .then((res) => res.data)
          .then((res) => {
            this.activity_loading=false;      
            res.data.forEach((question, index) => {
        console.log(question)

              this.$set(this.act.list_question_explan[index], 'test', question);
              if (++question_count == total_question) {
                this.isLoading = false;
              }
            })
       });
      }
    },
    setQuestionExplanExam() {
      this.activity_loading=true;      
      this.result = false;
        let question_count = 0;
        const question_group = this.act.list_question_explan.map((val) => {
          const a = val.question_id;
          return a ;
        });
        const data = {
          id:question_group
        }
        this.axios.post(`/bank/v1/question/public/detail_multiple`,data,{
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
        })
          .then((res) => res.data)
          .then((res) => {
            this.activity_loading=false;
            res.data.forEach((question, index) => {
              this.$set(this.act.list_question_explan[index], 'test', question);
              if (++question_count == this.act.list_question_explan.length) {
                this.isLoading = false;
              }
            })

          });      
    },
    setQuestion() {
      this.activity_loading=true;            
      this.result = false;
      const total_question = this.act.list_question.reduce((cur, val) => {
        return cur + val.list_question.length;
      }, 0);
      let question_count = 0;
      // this.act.list_question.forEach(({ list_question }) => {
        // console.log('list',list_question)
        // list_question.forEach((question, index) => {
        //   this.axios(`/bank/v1/question/public/detail/${question.question_id}`,{
        //     headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
        //   })
        //     .then((res) => res.data)
        //     .then((res) => {
        //       this.$set(list_question, index, res.data);
        //       if (++question_count == total_question) {
        //         this.isLoading = false;
        //       }
        //     });
        // });


        const question_group = this.act.list_question.map((val) => {
          const a = val.list_question[0].question_id;
          return a ;
        });
        const data = {
          id:question_group
        }
        this.axios.post(`/bank/v1/question/public/detail_multiple`,data,{
          headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
        })
          .then((res) => res.data)
          .then((res) => {
            this.activity_loading=false;      
            res.data.forEach((question, index) => {
              this.$set(this.act.list_question[index].list_question[0], 'test', question);
              if (++question_count == total_question) {
                this.isLoading = false;
              }
            })
       });
      // });
    },
    setQuestionExam() {
      this.activity_loading=true;            
      this.result = false;
      let total_question = 0;
      this.act.list_question_exam.forEach((value) => {
        total_question += value.groups.reduce((cur, val) => {
          return cur + val.list_question.length;
        }, 0);
      })

      // this.$set(this.act, "total_question", total_question);

      let question_count = 0;
      this.act.list_question_exam.forEach(({ groups }) => {
        // console.log('groups', groups);
        if (groups.length > 0) {
          // groups.total_question = total_question
          groups.forEach(( list_question, index) => {
            // list_question.list_question.forEach((question, index) => {
              // console.log('list', list_question.list_question);
              const question_group = list_question.list_question.map((val) => {
                const a = val.question_id;
                return a ;
              });
              const data = {
                id:question_group
              }
              // this.axios(`/bank/v1/question/public/detail/${question.question_id}`,{
              this.axios.post(`/bank/v1/question/public/detail_multiple`,data,{
                headers: { "Access-Key": process.env.VUE_APP_ACCESS_KEY }          
              })
                .then((res) => res.data)
                .then((res) => {
                  this.activity_loading=false;                        
                  res.data.forEach((question, index) => {
                    // console.log('res', question);
                    this.$set(list_question.list_question, index, question);
                    // console.log('list_question', list_question);
                    if (++question_count == total_question) {
                      this.isLoading = false;
                    }
                  })

                });
            // })
          });

        }
      });
    },
    submitReview(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer] ? [v.test.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };
      console.log("data", data);
      // console.log("act", act);
      // console.log("topik", topic);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          // this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          // }                    

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQues = false;
          this.resultNilai = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitReviewFalse(setting) {
      // console.log('act',act);      
      // console.log('topik',this.topics[this.topics_active].id);      
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer] ? [v.test.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };

      console.log("data", data);
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          // this.getUnlock(this.topics[this.topics_active].id, this.act.id);

          // let score = res.data.total_activity_score;
          // console.log('score',score)

          // if (score >= minimum ) {
          //   console.log('score',score)
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesFalse = false;
          this.resultNilaiFalse = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitPost(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer] ? [v.test.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };
      console.log("answer", data);
      // console.log("act", act);
      // console.log("topik", topic);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiPost=true;
          this.showQuesPost=false;          
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //   // if (score >= minimum ) {
          //     this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id, this.act.id);
          // }                    

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQues = false;
          this.resultNilai = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitExam(setting) {
      // console.log('act : ',setting);  
      // console.log('list_question_exam : ', this.act.list_question_exam); 
      this.activity_loading = true;
      let data = {}  
        const question_section = this.act.list_question_exam.map((value) => {
          const question_group = value.groups.map((val) => {
            const questions = val.list_question.map((v) => {
              const question_id = v.id;
              const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
              return {
                question_id,
                answer,
              };
            });
            return {
              group_id: val.group_id,
              questions,
            };
          })

          return {
            section_id: value.id,
            question_group
          }
        });

        data = {
          id: this.detailClass.id,
          topic_id: this.id_topic,
          activity_id: this.id_act,
          sections: question_section,
          question_group: []
        };
        this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiExam=true;
          this.showQuesExam=false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesExamFalse = false;
          this.resultNilaiExamFalse = true;
          this.timer_stop();
          this.totalSeconds=0;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitExamFalse(setting) {
      // console.log('act : ',setting);  
      // console.log('list_question_exam : ', this.act.list_question_exam); 
      this.activity_loading = true;
      let data = {}  
        const question_section = this.act.list_question_exam.map((value) => {
          const question_group = value.groups.map((val) => {
            const questions = val.list_question.map((v) => {
              const question_id = v.id;
              const answer = v.options[v.answer] ? [v.options[v.answer].text] : [];
              return {
                question_id,
                answer,
              };
            });
            return {
              group_id: val.group_id,
              questions,
            };
          })

          return {
            section_id: value.id,
            question_group
          }
        });

        data = {
          id: this.detailClass.id,
          topic_id: this.id_topic,
          activity_id: this.id_act,
          sections: question_section,
          question_group: []
        };
        this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiExamFalse=true;
          this.showQuesExamFalse=false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesExamFalse = false;
          this.resultNilaiExamFalse = true;
          this.timer_stop();
          this.totalSeconds=0;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitPostFalse(setting) {
      // console.log('act : ',setting);  
      // console.log('list_question_exam : ', this.act.list_question_exam); 
      this.activity_loading = true;

      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer] ? [v.test.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };
      
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.resultNilaiPostFalse=true;
          this.showQuesPostFalse=false;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;          
          // let minimum = setting.set_minimum_value.minimum_value;

          // if(setting.set_minimum_value.is_set===true){            
          //   // if (score >= minimum ) {
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   // }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          // this.getUnlock(this.topics[this.topics_active].id, this.act.id);

          // let score = res.data.total_activity_score;
          // console.log('score',score)

          // if (score >= minimum ) {
          //   console.log('score',score)
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }

          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesFalse = false;
          this.resultNilaiFalse = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitQuiz(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer] ? [v.test.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };
      console.log("data", data);

      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          // this.getActFalse(this.topics[this.topics_active].id, this.act.id);
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;

          // let minimum = setting.set_minimum_value.minimum_value;
          
          // if(setting.set_minimum_value.is_set===true){            
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }
          
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban terkirim",
          };
          this.showQues = false;
          this.resultNilai = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    submitQuizFalse(setting) {
      this.activity_loading = true;
      const question_group = this.act.list_question.map((val) => {
        const questions = val.list_question.map((v) => {
          const question_id = v.question_id;
          const answer = v.test.options[v.answer] ? [v.test.options[v.answer].text] : [];
          return {
            question_id,
            answer,
          };
        });
        return {
          group_id: val.group_id,
          questions,
        };
      });
      const data = {
        id: this.detailClass.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
        question_group,
      };

      console.log("param", data);
      this.axios
        .post("/users/v1/myprogram/answer", data)
        .then((res) => res.data)
        .then((res) => {
          this.act.current = 1;
          this.getActFalse(this.id_topic, this.act.id);
          // let score = res.data.total_activity_score;
          // // console.log('score',score)
          // let minimum = setting.set_minimum_value.minimum_value;
          
          // if(setting.set_minimum_value.is_set===true){            
          //   if (score >= minimum ) {
          //     // console.log('score',score)
          //     this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          //   }
          // } else{
          //   this.getUnlock(this.topics[this.topics_active].id,this.act.id);
          // }


          // this.review = true;
          this.result = res.data;
          this.notification = {
            state: true,
            message: "Jawaban Terkirim",
          };
          this.showQuesFalse = false;
          this.resultNilaiFalse = true;
        })
        .catch((error) => {
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        })
        .finally(() => {
          this.activity_loading = false;
        });
    },
    getChange(topic, act,type,setting) {
      this.activity_loading=true;      
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;          
          this.resultNilai = false;
          this.showQues = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error);
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
        });
    },
    getChangeFalse(topic, act,type,setting) {
      this.timer_stop()
      this.activity_loading=true;
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;
          this.resultNilaiFalse = false;
          this.showQuesFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error);
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
        });
    },
    getChangePost(topic, act,type,setting) {
      this.activity_loading=true;
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      // console.log(data);
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;
          this.resultNilaiPost = false;
          this.showQuesPost = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error.response);
          let errorCode = error.response.status
          let errorData = error.response.data

          if (errorCode == 400) {
            this.notification = {
              state: true,
              message: errorData.message,
            };
          }
        });
    },
    getChangePostFalse(topic, act,type,setting) {
      this.activity_loading=true;
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;
          this.resultNilaiPostFalse = false;
          this.showQuesPostFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getChangeExam(topic, act,type,setting) {
      this.activity_loading=true;
      const data = {
        // type:false,
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      // console.log(data);
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;
          this.resultNilaiExam = false;
          this.showQuesExam = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error.response);
          let errorCode = error.response.status
          let errorData = error.response.data

          if (errorCode == 400) {
            this.notification = {
              state: true,
              message: errorData.message,
            };
          }
        });
    },
    getChangeExamFalse(topic, act,type,setting) {
      this.activity_loading=true;
      const data = {
        member_redeem_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/update_is_complete`, data)
        .then(() => {
          this.activity_loading=false;
          this.resultNilaiExamFalse = false;
          this.showQuesExamFalse = true;
          this.questions.forEach((v) => {
            this.$set(v, "answer", null);
          });
          if (setting.is_show_timer === true) {
            this.timer_start(type,setting);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    changeStatus() {
      this.getUnlock(this.id_topic, this.id_act);
    },
    getUnlock(topic, act) {
      this.loadUpload=true
      const data = {
        program_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async () => {
          this.loadUpload=false
          this.getDetailClass();
          this.getActFalse(this.id_topic, this.act.id);
        })
        .catch((error) => {
          this.loadUpload=false          
          console.error(error);
        });
    },
    getUnlockEvaluasi(topic, act) {
      const data = {
        program_id: this.$route.params.id,
        topic_id: topic,
        activity_id: act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async () => {
          this.getDetailClass();
          this.getActFalse(this.id_topic, this.act.id);
          setTimeout(() => {
            this.getFormReview(this.detailClass.license, this.id_pro.id);
          }, 3000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getUnlockUpload() {
      const data = {
        program_id: this.$route.params.id,
        topic_id: this.id_topic,
        activity_id: this.id_act,
      };
      this.axios
        .post(`/users/v1/member/activity/unlock`, data)
        .then(async (res) => {
          this.notification = {
            state: true,
            message: res.data.message,
          };
          this.getDetailClass();
          this.getActFalse(this.id_topic, this.act.id);          
        })
        .catch((error) => {
          console.error(error);
          this.notification = {
            state: true,
            message: error.response.data.message,
          };
        });
    },
    repeat() {
      // if (setting.is_show_timer === true) {
      //   this.timer_start(type,setting);
      // }
      this.resultNilai = false;
      this.showQues = true;
      this.showExplanation=false;
      this.getActFalse(this.id_topic, this.act.id);      
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatFalse(type,setting) {
      // if (setting.is_show_timer === true) {
      //   this.timer_start(type,setting);
      // }
      // console.log(type)
      // console.log(setting)
      this.resultNilaiFalse = false;
      this.showQuesFalse = true;
      this.showExplanationFalse=false;
      this.getActFalse(this.id_topic, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatPost() {
      this.resultNilaiPost = false;
      this.showQuesPost = true;
      this.showExplanationPost=false;
      this.getActFalse(this.id_topic, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    repeatPostFalse(type,setting) {
      // if (setting.is_show_timer === true) {
      //   this.timer_start(type,setting);
      // }
      this.resultNilaiPostFalse = false;
      this.showQuesPostFalse = true;
      this.showExplanationPostFalse=false;
      this.getActFalse(this.id_topic, this.act.id);
      this.questions.forEach((v) => {
        this.$set(v, "answer", null);
      });
    },
    question_status(index) {
      if (!this.show_direction) {
        if (!index || !this.act.current) {
          return "bg-accent-default";
        }
        if (index == this.act.current) {
          return "bg-accent-orange";
        }

        if (this.questionsExam[index - 1].answer != undefined) {
          return "bg-accent-green";
        }
      }
      return "bg-accent-default";
    },
    goNumber(number) {
      this.act.current = number;
    },
    timer_start(type,setting) {
      this.timer_stop()
      this.totalSeconds = 0
      this.totalSeconds=setting.timer*60;
      this.timer = setInterval(() => {
        this.totalSeconds-=1;
        if (this.totalSeconds === 0) {
          if (type === 'pre_test') {
            this.submitReview(setting)
          }else if (type === 'post_test') {
            this.submitPostFalse(setting)
          }else if (type === 'exam') {
            this.submitExamFalse(setting)
          }else{
            this.submitQuizFalse(setting)
          }
          this.totalSeconds = 0;
          clearInterval(this.timer);
        }
      }, 1000);
    },
    timer_stop() {
      clearInterval(this.timer);
    },
    redirect(type,setting){
      this.activity_loading=true
      this.getChange(this.id_topic, this.act.id,type,setting)
      // this.repeat();      
    },
    redirectFalse(type,setting){
      this.activity_loading=true
      this.getChangeFalse(this.id_topic, this.act.id,type,setting)
    },
    redirectPost(type,setting){
      this.activity_loading=true
      this.getChangePost(this.id_topic, this.act.id,type,setting)
      // this.repeat();      
    },
    redirectPostFalse(type,setting){
      this.activity_loading=true
      this.getChangePostFalse(this.id_topic, this.act.id,type,setting)
    },
    redirectExam(type,setting){
      this.activity_loading=true
      this.getChangeExam(this.id_topic, this.act.id,type,setting)
      // this.repeat();      
    },
    redirectExamFalse(type,setting){
      this.activity_loading=true
      this.getChangeExamFalse(this.id_topic, this.act.id,type,setting)
    },
    currentDateTime(a) {
      return moment(a).format("L");
    },
    day(a) {
      // moment.lang("de").format('LLL');
      if(a ==='sunday'){
        return 'Minggu'
      }else if(a==='monday'){return 'Senin'}else if(a==='tuesday'){return 'Selasa'}else if(a==='wednesday'){return 'Rabu'}else if(a==='thursday'){return 'Kamis'}
      else{
        return 'Jumat'
      }
      // return moment(a).lang("id").format("D");
    },
    date(a) {
      return a ? moment(a).locale('en-gb').format("L") : null;
    },
    dateTime(value) {
      var minut=value*60;
      var hours = `${Math.floor(minut / 3600)}`.padStart(2, 0);
      var minutes = `${Math.floor(minut / 60) % 60}`.padStart(2, 0);
      var seconds = `${minut % 60}`.padStart(2, 0);
      return `${hours}:${minutes}:${seconds}`;
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .output("blob")
        .then((blob) => {
          let formData = new FormData();
          let certif = blob;

          formData.append("file", certif, "Sertifikat.pdf");
          formData.append("source", "upload");
          formData.append("title", "Sertifikat");
        });
    },
    hasGenerated($event) {
      console.log($event);
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
    ctfPeserta(type) {
      this.typeCertif = type;
      this.$refs.certifPeserta.generatePdf();
    },
    ctfKompetensi() {
      this.$refs.certifKom.generatePdf();
    },
    send() {
      this.subSend = true;
      const data = {
        license: this.detailClass.license,
        program_id: this.program.id,
        review: this.ulasan,
        star: this.rating,
      };
      this.axios
        .post(`/program_review/v1/create`, data, {
          headers: { "Content-Type": "application/json", },
        })
        .then(async () => {
          this.subSend = false;
          this.dialog=false;
          this.getDetailClass();
          // this.getReviewClass();
          this.notification = {
            state: true,
            message: "Ulasan Terkirim",
          };
        })
        .catch((error) => {
          this.subSend = false;
          this.rating = null;
          this.ulasan = "";
          let message = error.response ? error.response.data.message : error.message;
          this.notification = {
            state: true,
            message,
          };
          console.error(error);
        });
    },
    reloadPage(){
      location.reload();
    }
  },
  watch: {
    review(val) {
      if (val) {
        this.answer = [1, 3];
      } else {
        this.answer = null;
      }
    },
  },
};
</script>

<style>
  #frame{
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  }
#player .ytp-chrome-top-buttons {
  display: none !important;
}
#player .ytp-pause-overlay-controls-hidden .ytp-pause-overlay {
  display: none !important;
}
iframe {
  width: 100%;
  height: 100%;
}
.videoku .slider{
  display: none!important;
}

.custom-table.v-data-table--fixed-header thead th {
    box-shadow: unset !important;
    color: #828080 !important;
    background: #ebebee!important;
}

.custom-panels {
  border-top: thin solid;
  border-bottom: thin solid;
  border-color: rgba(0, 0, 0, 0.12);
}
.custom-panels .v-expansion-panel:before {
  box-shadow: none;
}
.custom-panels .v-expansion-panel--active + .v-expansion-panel,
.v-expansion-panel--active:not(:first-child) {
  margin-top: unset !important;
}
.custom-panels .v-expansion-panel-content__wrap {
  padding: unset !important;
}
.custom-panels .v-expansion-panel--active + .v-expansion-panel:after,
.custom-panels .v-expansion-panel--active:not(:first-child):after {
  opacity: unset;
}
.options .v-list-item {
  border: thin solid #d3dce6;
}
.options .v-list-item-group .v-list-item--active {
  border-color: currentColor;
}
.btn-img {
  background-color: #f05326;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 12px 16px;
  position: relative;
  border-radius: 4px;
}
.drop input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.drop {
  border: 1px dashed #ccc;
  border-radius: 2px;
  height: 60vh;
  width: 100%;
  vertical-align: middle;
}
.img-cover {
  width: 100%;
  object-fit: contain;
  height: 250px;
}
.btn-float {
  position: absolute;
  top: 10%;
  right: 5%;
}
.bg-label {
  background: rgb(245 245 245);
  padding: 8px;
  border-radius: 100%;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}
.disabledbutton {
    pointer-events: none;
    opacity: 0.4;
}
#tagImg img{
  max-width: 100%;
  height: auto;
}

#Iframe-Master-CC-and-Rs {
  max-width: 512px;
  max-height: 100%; 
  overflow: hidden;
}

/* inner wrapper: make responsive */
.responsive-wrapper {
  position: relative;
  height: 0; 
}
.responsive-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: none;
}

/* padding-bottom = h/w as % -- sets aspect ratio */
/* YouTube video aspect ratio */
.responsive-wrapper-wxh-572x612 {
  padding-bottom: 107%;
}

/* general styles */
/* ============== */
.set-border {
  border: 5px inset #4f4f4f;
}
.set-box-shadow { 
  -webkit-box-shadow: 4px 4px 14px #4f4f4f;
  -moz-box-shadow: 4px 4px 14px #4f4f4f;
  box-shadow: 4px 4px 14px #4f4f4f;
}
.set-padding {
  padding: 40px;
}
.set-margin {
  margin: 30px;
}
.center-block-horiz {
  margin-left: auto !important;
  margin-right: auto !important;
}
#secondaryToolbarToggle{
  display: none!important;
}
.media-controls-container .slider{
  display: none!important;
}
#desc-video img{
  width: 100%;
}
#videoku .media-controls-container, .media-controls-container *{background: #000;}
video::-webkit-media-controls-timeline{display: none!important;}
video::-webkit-media-controls-timeline {
  -webkit-appearance: media-slider;
  display: none!important;
}

video::-internal-media-controls-download-button {
  display:none;
}
</style>