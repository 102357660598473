<template>
  <div class="mt-n3 mx-n3">
    <hr style="border-color: transparent;">
    <section class="mt-5 mb-5 white">
      <div class="col-md-10 mx-auto">
          <img src="@/assets/images/icon/banner.jpg" class="img-kategori"/>
          <p class="fs-24 fs-xs-20 font-weight-bold my-2">Siap Kerja Dengan Kartu Prakerja</p>
          <p>
            PT Yureka Edukasi Cipta yang memiliki nama brand YEC CO ID berdiri pada tahun 2010 dengan nama awal Eureka Tour, sebuah layanan jasa tour spesialis Kampung Inggris. Saat ini YEC fokus membantu setiap peserta menjadi individu lebih baik. Tidak hanya sebatas menjadi jasa tour, namun juga menjadi konsultan pendidikan dan media yang membantu menjadikan diri lebih baik dengan kemudahan akses informasi dan program pelatihan terkait tes TOEFL, IELTS, Persiapan Kerja dan Pembelajaran Bahasa Inggris Sehari-hari.
          </p>
          <p>
            Serta pada tahun 2020, YEC CO ID bergabung bersama program Kartu Prakerja, memberikan pelatihan kepada peserta kartu prakerja yang memiliki keinginan untuk mengembangkan keahlian agar lebih percaya diri terjun ke dunia kerja, maupun membuat usaha.
          </p>
          <div class="pt-3 tab-prakerja">
            <div class="row">
              <div class="col-md-4">
                <v-card
                  class="mx-auto sticky-sidebar"
                >
                  <v-list id="tab-ver">
                    <v-list-item-group>
                      <v-list-item href="#program">
                        <v-list-item-content>
                          <v-list-item-title>Program Kartu Prakerja</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#langkah">
                        <v-list-item-content>
                          <v-list-item-title>Langkah Mengikuti Program Prakerja</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#mengapa">
                        <v-list-item-content>
                          <v-list-item-title>Mengapa Harus Memilih Kelas Pelatihan di YEC.CO.ID?</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#kelas">
                        <v-list-item-content>
                          <v-list-item-title>Kelas-kelas Yang Dirancang oleh YEC.CO.ID</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item href="#faq">
                        <v-list-item-content>
                          <v-list-item-title>FAQ</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </div>
              <div class="col-md-8">
                <div id="program" class="mb-4">
                  <h3 class="font-weight-bold pb-2">Siap Kerja Dengan Kartu Prakerja</h3>
                  <p>Apa itu program kartu prakerja?</p>
                  <p>Program Kartu Prakerja adalah program pengembangan kompetensi kerja dan kewirausahaan yang ditujukan untuk pencari kerja, pekerja/buruh yang terkena pemutusan hubungan kerja, dan/atau pekerja/buruh yang membutuhkan peningkatan kompetensi, termasuk pelaku usaha mikro dan kecil. Kami percaya bahwa masyarakat Indonesia sesungguhnya ingin selalu meningkatkan kemampuannya. Program ini didesain sebagai sebuah produk dan dikemas sedemikian rupa agar memberikan nilai bagi pengguna sekaligus memberikan nilai bagi sektor swasta.</p>
                  <p>Jalan digital melalui marketplace dipilih untuk memudahkan pengguna mencari, membandingkan, memilih dan memberi evaluasi. Hanya dengan cara ini, produk bisa terus diperbaiki, tumbuh dan relevan. Menggandeng pelaku usaha swasta, program ini adalah wujud kerjasama pemerintah dan swasta dalam melayani masyarakat dengan semangat gotong royong demi SDM unggul, Indonesia maju. <a href="https://www.prakerja.go.id/tentang-prakerja"> (https://www.prakerja.go.id/tentang-prakerja) </a>.</p>
                  <p>
                    Program ini bisa diikuti seluruh masyarakat Indonesia yang memiliki kriteria berikut:
                    <ol>
                      <li>Warga negara Indonesia berusia paling rendah 18 (delapan belas) tahun</li>
                      <li>Tidak sedang mengikuti pendidikan formal</li>
                    </ol>
                  </p>
                  <p class="mb-0">Sedangkan, siapa saja yang tidak bisa mengikuti program Kartu Prakerja, diantaranya:</p>
                  <ol>
                    <li>Pejabat Negara</li>
                    <li>Pimpinan dan Anggota Dewan Perwakilan Rakyat Daerah</li>
                    <li>Aparatur Sipil Negara</li>
                    <li>Prajurit Tentara Nasional Indonesia</li>
                    <li>Anggota Kepolisian Negara Republik Indonesia</li>
                    <li>Kepala Desa dan perangkat desa</li>
                    <li>Direksi, Komisaris, dan Dewan Pengawas pada badan usaha milik negara atau badan usaha milik daerah</li>
                  </ol>
                  <p>
                    Keuntungan mengikuti Program Kartu Prakerja <br>
                    Mendapatkan subsidi <b> non tunai </b> sebesar <b> 1 juta rupiah </b>, yang dapat digunakan untuk membeli 
                    pelatihan online
                  </p>
                  <p>
                    Setelah mengikuti pelatihan, akan mendapatkan insentif sebesar <b> 600 ribu sebanyak 4 kali </b> dan <b> 150 ribu </b> dari pengisian survey. Info lebih lanjut mengenai program ini dapat dilihat di <a href="https://www.prakerja.go.id"> www.prakerja.go.id </a>. Contact center: <b class="primar--text"> 08001503001 </b>
                  </p>
                </div>
                <div id="langkah" class="mb-4">
                  <h3 class="font-weight-bold mb-2">Langkah Mengikuti Program Kartu Prakerja</h3>
                  <ol class="mb-3">
                    <img src="@/assets/images/icon/icon-1.png" class="w-100">
                    <li>
                      <b>Daftar Akun</b>
                      <p>
                        Silahkan melakukan pendaftaran di <br>
                        Daftar Kartu Prakerja Sekarang (diisi link url berikut <a href="https://dashboard.prakerja.go.id/daftar"> https://dashboard.prakerja.go.id/daftar</a> )
                        Siapkan e-mail, NIK, nomor KK dan nomor HP yang masih aktif untuk mendaftar.
                        Pastikan Data Kamu Sesuai.
                      </p>
                    </li>
                    <img src="@/assets/images/icon/icon-2.png" class="w-100">
                    <li>
                      <b>Ikut Seleksi</b>
                      <ul>
                        <li>Ikuti tes motivasi & kemampuan dasar untuk bisa mengikuti seleksi gelombang</li>
                        <li>Jangan lupa Klik “Gabung”, saat gelombang dibuka</li>
                        <li>Kemudian tunggu pengumuman hasilnya.</li>
                      </ul>
                      <p class="font-weight-bold">Apabila kamu lolos seleksi</p>
                    </li>
                    <img src="@/assets/images/icon/icon-3.png" class="w-100">
                    <li>
                      <b>Sambungkan Rekening/E-wallet</b>
                      <p>
                        Sambungkan rekening/e-wallet di salah satu Mitra Pembayaran Program Kartu Prakerja. Pastikan pemilik rekening/e-wallet yang disambungkan sama dengan pemilik akun prakerja
                      </p>
                    </li>
                    <img src="@/assets/images/icon/icon-4.png" class="w-100">
                    <li>
                      <b>Pilih Pelatihan</b>
                      <p>
                        Kamu dapat Membeli Pelatihanmu di Mitra Platform Digital yang bekerja sama dengan prakerja salah satunya YEC CO ID kemudian bayar dengan Kartu Prakerja.
                      </p>
                    </li>
                    <img src="@/assets/images/icon/icon-5.png" class="w-100">
                    <li class="mb-2">
                      <b>Selesaikan Pelatihan dan Dapatkan Sertifikat</b>
                    </li>
                    <img src="@/assets/images/icon/icon-6.png" class="w-100">
                    <li class="mb-2">
                      <b>Beri Rating dan Ulasan di Pelatihan yang kamu ikuti</b>
                    </li>
                    <img src="@/assets/images/icon/icon-7.png" class="w-100">
                    <li>
                      <b>Mendapatkan Insentif Biaya Mencari Kerja Cair</b>
                      <p>
                        Pastikan pemilik rekening/e-wallet  sama dengan pemilik akun prakerja.
                        Setelah sertifikat muncul di Dashboard Prakerja maka akan mendapatkan jadwal pencairan insentif Rp600rb/bulan selama 4 bulan. Insentif biasanya keluar sesuai dengan jadwal.
                      </p>
                    </li>
                    <img src="@/assets/images/icon/icon-8.png" class="w-100">
                    <li>
                      <b>Mendapatkan Insentif Pengisian Survei Evaluasi</b>
                      <p>
                        Isi 3 survei yang diberikan Program Kartu Prakerja dan dapatkan insentif Rp50 ribu untuk setiap survei.
                      </p>
                    </li>
                  </ol>
                  <p class="font-weight-bold mb-1">Beli Pelatihanmu Disini!</p>
                  <p>YEC CO ID berafiliasi dengan beberapa Digital Platform, yaitu  Karier.mu, Tokopedia, Pijar Mahir, dan Pintar</p>
                  <div class="row mb-3">
                    <div class="col-md-3">
                      <a href="https://prakerja.karier.mu/mitra/yec-co-id" target="_blank">
                        <img src="@/assets/images/icon/image-5.png">
                      </a>
                    </div>
                    <div class="col-md-3">
                      <a href="https://www.tokopedia.com/kartu-prakerja/partner/yec-co-id" target="_blank">
                        <img src="@/assets/images/icon/image-6.png">
                      </a>
                    </div>
                    <div class="col-md-3">
                      <a href="https://pijarmahir.id/institution-partner/yec" target="_blank">
                        <img src="@/assets/images/icon/image-8.png">
                      </a>
                    </div>
                    <div class="col-md-3">
                      <a href="https://pintar.co/kartuprakerja/cari/hasil?keyword=yec" target="_blank">
                        <img src="@/assets/images/pintar.png" style="height: 60px; width: auto">
                      </a>
                    </div>
                  </div>
                  <!-- <div class="mb-2">
                    <p class="font-weight-bold mb-1">
                      Link pembelian kelas YEC X Sekolahmu
                    </p>
                    <a href="https://prakerja.karier.mu/mitra/yec-co-id" class="text-decoration-underline" target="_blank">Mitra | Yec.co.id Sekolah.mu (karier.mu)</a>
                  </div>
                  <div class="mb-2">                    
                    <p class="font-weight-bold mb-1">
                      Link Pijar Mahir
                    </p>
                    <a href="https://prakerja.karier.mu/mitra/yec-co-id" class="text-decoration-underline" target="_blank">Pijar Mahir</a>
                  </div>
                  <div class="mb-2">                    
                    <p class="font-weight-bold mb-1">
                      Link Pintaria
                    </p>
                    <a href="https://pintaria.com/kartuprakerja" class="text-decoration-underline" target="_blank">https://pintaria.com/kartuprakerja</a>
                  </div>
                  <div class="mb-2">                    
                    <p class="font-weight-bold mb-1">
                      Link pembelian kelas by Tokopedia
                    </p>
                    <a href="https://pijarmahir.id/category/Kartu%20Prakerja?id=78&page=1&limit=10&sort=0" class="text-decoration-underline" target="_blank">Daftar Kartu Prakerja Online di Tokopedia!</a>
                    <a href="https://bakat.yec.co.id/auth/login" class="text-decoration-underline" target="_blank">https://bakat.yec.co.id/auth/login</a>
                  </div> -->
                </div>
                <div id="mengapa" class="mb-4">
                  <h3 class="font-weight-bold pb-2">Mengapa Harus Memilih Kelas Pelatihan di YEC.CO.ID ?</h3>
                  <v-list three-line>
                    <template>
                      <v-list-item>
                        <v-list-item-avatar width="64" height="64">
                          <img src="@/assets/images/icon/Group-1.png">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="mb-1 font-weight-bold">Instruktur Berkompeten</v-list-item-title>                        
                           <v-list-item-subtitle>Instruktur kelas berupa putra-putri daerah yang berkompeten di bidangnya.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar width="64" height="64">
                          <img src="@/assets/images/icon/Group-2.png">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="mb-1 font-weight-bold">Grup Eksklusif</v-list-item-title>                        
                           <v-list-item-subtitle >Grup Pengembangan Karir GRATIS dengan webinar eksklusif secara berkala.</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-avatar width="64" height="64">
                          <img src="@/assets/images/icon/Group-3.png">
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title class="mb-1 font-weight-bold">Admin</v-list-item-title>                        
                           <v-list-item-subtitle class="mb-2">Admin cepat tanggap di semua media sosial.</v-list-item-subtitle>
                           <span class="mb-2">
                            <a
                              href="https://www.instagram.com/yec.prakerja/"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/instagram 1.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a
                              href="https://t.me/joinchat/HOIYkUuvNkC4pyuN2E-z4w"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/telegram 1.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a href="http://facebook.com/groups/prakerja.yec" 
                              target="_blank">
                              <img
                                src="@/assets/images/FB.png"
                                alt="Avatar"
                                class="mr-2"
                                style="width: 25px"
                              />
                            </a>
                            <a
                              href="https://www.tiktok.com/@panduanprakerja?lang=en"
                              target="_blank"
                            >
                              <img
                                src="@/assets/images/tiktok.png"
                                alt="Avatar"
                                class=""
                                style="width: 25px"
                              />
                            </a>
                          </span>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </div>
                <div id="kelas" class="mb-4">
                <h3 class="font-weight-bold pb-2">Kelas-kelas Yang Dirancang oleh YEC.CO.ID</h3>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732157066798986-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Menjual Produk melalui Media Sosial bagi Pedagang Melalui Internet</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                          Menjual Produk melalui Media Sosial bagi Pedagang Melalui Internet adalah kelas yang disusun khusus untuk peserta yang ingin masuk dalam dunia bisnis menjadi penjual online. Pelatihan ini menggunakan metode ajar SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book. Kelas ini merujuk pada KBJI nomor 5244.02 dan Kompetensi berdasarkan pada SKKNI. 
                        </p>
                      </v-card-text>              
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732132744069930-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengembangkan Akun YouTube dan Tiktok bagi Seniman Digital</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                          Program ini (Mengembangkan Akun YouTube dan Tiktok bagi Seniman Digital) adalah kelas yang disusun khusus untuk peserta yang ingin menjadi seorang content creator pada platform Tiktok dan Youtube. Program ini disusun menggunakan sumber rujukan KBJI nomor 2166.02 dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah Hybrid, dimana akan ada beberapa metode ajar yaitu ceramah, peragaan, webinar, dan memahami e-book.
                        </p>
                      </v-card-text>              
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732356403541915-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengolah Dokumen dengan Ms.Word & Ms. Excel untuk Petugas Input Data</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                          Mengolah Dokumen dengan Ms.Word & Ms. Excel untuk Petugas Input Data adalah kelas yang disusun khusus untuk peserta yang ingin menjadi petugas input data dengan meningkatkan ketrampilan mengolah dokumen dalam Ms. Word dan Ms. Excel. Program ini disusun menggunakan sumber rujukan KBJI dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dengan menggunakan metode ajar yaitu ceramah, peragaan, dan memahami e-book.
                        </p>
                      </v-card-text>              
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732322328379325-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengembangkan Usaha menjadi Penjual Minuman Kopi</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                          Program ini (Mengembangkan Usaha menjadi Penjual Minuman Kopi) adalah kelas yang disusun khusus bagi peserta yang ingin menggeluti bidang penjualan usaha kopi. Program ini disusun menggunakan sumber rujukan KBJI nomor 5212.01 dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dimana akan ada beberapa metode ajar yaitu ceramah, peragaan, dan memahami e-book.
                        </p>
                      </v-card-text>              
                    </v-card>
                  </div>
                  <!-- <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="@/assets/images/icon/Soto.png"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengembangkan Usaha menjadi Penjual Makanan Soto</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                          Mengembangkan Usaha menjadi Penjual Makanan Soto adalah kelas yang disusun khusus untuk peserta yang ingin menjadi Penjual Makanan khususnya Soto Banjar dan Soto Lamongan. Program ini disusun menggunakan sumber rujukan KBJI dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book.
                        </p>
                      </v-card-text>              
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="@/assets/images/icon/International.png"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengembangkan Usaha Menjadi Penjual Makanan Barat</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                          Program ini (Mengembangkan Usaha Menjadi Penjual Makanan Barat) adalah kelas yang disusun khusus untuk peserta yang ingin mengembangkan usaha kuliner makanan barat Steak dan Pizza Teflon. Metode ajar yang digunakan adalah SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book.Kelas ini merujuk pada KBJI dan Kompetensi berdasarkan pada SKKNI.
                        </p>
                      </v-card-text>              
                    </v-card>
                  </div> -->
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732298629674843-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengembangkan Usaha menjadi Penjual Aneka Kue</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                          Mengembangkan Usaha menjadi Penjual Aneka Kue adalah kelas yang disusun khusus untuk peserta yang ingin menjadi Penjual Kue khususnya kue bolu, donat, dan kue kekinian “dessert box”. Program ini disusun menggunakan sumber rujukan KBJI dan kompetensi merujuk pada SKKNI. Metode ajar yang digunakan dalam pelatihan ini adalah SPL (Self Paced Learning), dengan menggunakan metode ajar ceramah, peragaan, dan membaca e-book.
                        </p>
                      </v-card-text>              
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732466358132376-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Merancang & Mengembangkan Website Wordpress bagi Pengembang Web Pemula</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Program ini disusun bagi peserta yang tertarik belajar pembuatan website dari nol tanpa perlu tahu terkait koding dan bahasa pemrograman. Selain membuat website, peserta juga akan mempelajari langkah-langkah mendesain website dan suatu saat ingin bekerja sebagai pekerja lepas (freelancer). Kompetensi yang disasar dari program ini adalah mandiri dan berorientasi pada tindakan.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732393574213370-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Menggunakan Bahasa Inggris untuk Operator Layanan Penjawab</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Program ini ditujukan untuk Operator Layanan Penjawab dengan fokus pembelajaran pada Bahasa Inggris dasar untuk menunjang kompetensi pendukung dalam pekerjaan. Kompetensi yang disasar adalah peserta mampu memperkenalkan diri, menyapa tamu, menyambut tamu, menerima tamu, melepas kepergian tamu, hingga menangani keluhan pelanggan dalam Bahasa Inggris.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732375242644952-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengembangkan Usaha Mode Menjadi Pemilik Toko Pakaian</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Program ini ditujukan untuk pemula yang ingin mengembangkan dan membuka usaha toko pakaian. Sumber rujukan dari program ini KBJI 5221.01 dan kompetensinya merujuk pada SKKNI No 217 Tahun 2009 dan SKKNI No 177 Tahun 2020. Kompetensi yang disasar dari program ini adalah peserta dapat merencanakan usaha, membuat administrasi usaha, operasi usaha, dan mengevaluasi hasil usaha. Dengan hasil akhir peserta dapat memiliki kemampuan mengelola dan membuka usaha sebagai pemilik toko pakaian.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732419571883927-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengembangkan Usaha Pertanian bagi Petani Serealia</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Kelas Mengembangkan Usaha Pertanian Bagi Petani Serealia adalah program yang ditujukan untuk petani serealia pemula sehingga setidaknya mampu untuk membudidayakan tanaman serealia, khususnya jagung. Kompetensi yang disasar dari program ini adalah peserta memahami dasar-dasar pertanian tanaman serealia khususnya tanaman jagung, mampu merancang perhitungan usaha pertanian, dan melakukan kerjasama kemitraan pada bidang usaha pertanian.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1642732442407821694-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengolah Makanan Fine Dining bagi Juru Masak di Restoran</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Kelas Makanan Fine Dining bagi Juru Masak di Restoran adalah program yang ditujukan bagi juru masak pemula sehingga setidaknya mampu untuk mengolah makanan yang disajikan di restoran. Kompetensi yang disasar dari program ini adalah peserta memahami dasar pengetahuan memasak dan pengetahuan tentang dapur, mampu mengolah makanan sesuai standar, dan bersikap disiplin dalam menerapkan prosedur keamanan pangan.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="https://storage.googleapis.com/ytc-cdn-prod/prakerja-new/company/60dd42e4ba82458c8b28896d/images/1640573797884470901-cover.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Merencanakan Pengelolaan Akuntansi dan Pajak bagi Akuntan</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Kelas Merencanakan Pengelolaan Akuntansi dan Pajak bagi Akuntan adalah program yang ditujukan untuk akuntan pemula. Kompetensi yang disasar adalah peserta mampu membuat laporan keuangan meliputi jurnal umum, jurnal penyesuaian, buku besar, laporan perubahan modal, hingga neraca; mampu menghitung Penghasilan Kena Pajak dan Penghasilan Tidak Kena Pajak untuk Wajib Pajak Pribadi maupun Badan Usaha; mampu melakukan pembayaran pajak dan melaporkan SPT sesuai dengan peraturan yang ada di Indonesia.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="@/assets/images/reparasi.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Melakukan Reparasi Sepeda Motor (Matic) bagi Mekanik Sepeda Motor</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Kelas Melakukan Reparasi Sepeda Motor (Matic) bagi Mekanik Sepeda Motor adalah program yang ditujukan bagi mekanik sepeda motor pemula dengan focus pembelajaran pada perawatan dan teknik reparasi bagian sistem mesin pada sepeda motor. Kompetensi yang disasar dari program ini adalah peserta memahami pengetahuan dasar perawatan sepeda motor, mampu merawat dan mereparasi sepeda motor, serta mampu bersikap professional dalam melakukan perawatan sepeda motor.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="@/assets/images/sd.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengajar Bahasa Inggris Sekolah Dasar untuk Tutor Bahasa</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Program ini ditujukan untuk tutor Bahasa Inggris pemula sehingga mampu melakukan pembelajaran Bahasa Inggris, khususnya dapat mengidentifikasi dan memahami karakter, penyelenggaraan pembelajaran, dan komunikasi antara tutor dengan peserta didik usia sekolah dasar. Kompetensi dari program ini adalah peserta mampu mengidentifikasi karakteristik peserta didik, memahami dasar teori belajar, melaksanakan pembelajaran bahasa inggris untuk sekolah dasar, dan melakukan komunikasi dengan peserta didik.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col-md-10 pa-0 mb-3">
                    <v-card class="mx-auto px-0" elevation="0">
                      <img
                        src="@/assets/images/menengah.jpg"
                        class="img-kategori"
                      />

                      <v-card-subtitle class="px-0 pb-1">
                        <h3 class="color-black">Mengajar Bahasa Inggris Sekolah Menengah untuk Tutor Bahasa</h3>
                      </v-card-subtitle>

                      <v-card-text class=" px-0 pt-0">
                        <p class="mb-0 fs-14">
                         Program ini ditujukan untuk tutor Bahasa Inggris pemula. Melalui program ini, peserta setidaknya mampu melakukan pengajaran Bahasa Inggris, khususnya dapat mengidentifikasi karakter peserta didik, menyelenggarakan pembelajaran, dan dapat berkomunikasi baik dengan peserta didik usia sekolah menengah. Sasaran kompetensi program ini adalah peserta mampu memahami karakteristik peserta didik, memahami dasar teori belajar, melaksanakan pembelajaran, dan berkomunikasi secara baik dengan peserta didik.
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div id="faq" class="mb-4">
                  <h3 class="font-weight-bold pb-2">FAQ</h3>
                  <v-expansion-panels accordion outlined>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah ada batas waktu penggunaan saldo pelatihan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Ya. Kamu bisa menggunakan saldo pelatihan selama 30 (tiga puluh) hari sejak diterima di dashboard prakerjamu. Jika sudah melebihi 30 (tiga puluh) hari dan kamu belum membeli pelatihan, maka kartu prakerja kamu akan dinonaktifkan dan saldo pelatihan kamu akan hangus dan dikembalikan ke Rekening Dana Prakerja. Kamu juga tidak dapat mengikuti kembali program kartu prakerja. 
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apakah saldo pelatihan 1.000.000 bisa dicairkan?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        Tidak bisa, saldo hanya bisa digunakan untuk membeli pelatihan di platform digital mitra, salah satunya YEC di Sekolah.mu
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Apa keunggulan pelatihan di YEC CO ID?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                       <ul>
                          <li>Jika ada masalah saat akses pelatihan, silakan sampaikan ke Grup FB, WA atau TG Prakerja YEC. Admin akan merespon cepat kendala kamu</li>
                          <li>Materi mudah dipahami dan juga disediakan latihan soal</li>
                       </ul>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana cara membeli kelas YEC CO ID diplatform karier.mu menggunakan Kartu Prakerja?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ol>
                          <li>Klik link berikut: <a href="https://prakerja.karier.mu/mitra/yec-co-id">https://prakerja.karier.mu/mitra/yec-co-id</a></li>
                          <li>Pilih Pelatihan yang kamu minati</li>
                          <li>Bayar dengan Kartu Prakerja pilih pembayaran “Ikuti dengan Kartu Prakerja”</li>
                          <li>Apabila muncul tulisan “kamu belum login”, silahkan login terlebih dahulu</li>
                          <li>Apabila belum memiliki akun, silahkan pilih “Daftar Disini” dan ikuti prosesnya</li>
                          <li>Apabila sudah berhasil login, saatnya kamu mengkonfirmasi pembayaran. Silahkan isi Nomer Kartu Prakerja kamu dan Pilih “Lanjut Pembayaran”</li>
                          <li>Kemudian silahkan mengisi OTP, kode OTP akan dikirimkan melalui SMS</li>
                          <li>Pembelian pelatihan kamu berhasil! Pilih “Mulai Belajar” untuk melihat Pelatihan yang kamu ikuti</li>
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Cara mengakses kelas yang sudah dibeli di platform karier.mu?</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ol class="mb-2">
                          <li>Log in di website <a href="https://prakerja.karier.mu ">https://prakerja.karier.mu </a></li>
                          <li>Cek <b>programmu </b></li>
                          <li>Akan ditampilkan pelatihan yang sudah dibeli </li>
                          <li>Klik pelatihan yang sudah dibeli </li>
                          <li>Kerjakan setiap aktivitas hingga <b>tercentang hijau</b> dan progress berjalan hingga <b>100%</b></li>
                        </ol>
                        <!-- <p class="font-weight-bold mb-0">
                          Video panduan akses kelas : 
                        </p>
                        <p>
                          <a href="https://www.youtube.com/watch?v=mIXpzouX5_w">https://www.youtube.com/watch?v=mIXpzouX5_w</a>
                        </p> -->
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Cara Membeli Pelatihan di Pijar Mahir</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol class="mb-2">
                        <li>Silahkan cek terlebih dahulu apakah kamu sudah memiliki akun di Pijar Mahir, apabila belum silahkan lakukan pendaftaran.</li>
                        <li>
                          Apabila sudah memiliki akun Pijar Mahir. Silahkan langsung melakukan beli pelatihan di Pijar Mahir. Berikut langkah-langkahnya:
                          <ul>
                            <li>Pilih pelatihan kamu minati di Pijar Mahir</li>
                            <li>Pilih Beli Sekarang</li>
                            <li>Pilih Metode Pembayaran Menggunakan <b>“Kartu Prakerja”</b></li>
                            <li>Klik tombol <b>“Bayar”</b> untuk melnajutkan pembayaran</li>
                            <li>Peserta akan mendapatkan <b>email status pemesanan pelatihan</b></li>
                            <li>Silahkan Melanjutkan proses pembayaran dengan <b>memasukkan Nomor Kartu Prakerja</b> di Pijar Mahir</li>
                            <li>Masukkan kode OTP</li>
                            <li>Pembayaran Berhasil</li>
                          </ul>
                        </li>
                        <li>
                          <p class="font-weight-bold mb-0">Atau</p>
                          <ul>
                            <li>Pilih pelatihan kamu minati di Pijar Mahir</li>
                            <li>Pilih Beli Sekarang</li>
                            <li>Pilih Metode Pembayaran Menggunakan <b>“Kartu Prakerja”</b></li>
                            <li>Klik tombol <b>“Bayar”</b> untuk melnajutkan pembayaran</li>
                            <li>Cek menu Transaksi untuk melanjutkan pembayaran, klik <b>“lanjutkan pembayaran”</b></li>
                            <li>Apabila ada keterangan <b>"Pembayaran Berhasil"</b> maka telah berhasil dibayar</li>
                          </ul>
                        </li>
                        <li>
                          Setelah pembayaran berhasil Anda akan mendapatkan “Kode Voucher”, dapat dilihat di detail transaksi
                        </li>
                        <li>
                          Langkah berikutnya silahkan tukarkan kode vouchermu. Berikut <b>prosedur penukaran Kode Voucher</b>:
                          <ul>
                            <li>Silakan masuk ke web <a href="https://bakat.yec.co.id/" target="_blank">https://bakat.yec.co.id/</a> </li>
                            <li>Pilih "Daftar" untuk membuat akun atau "Log in" menggunakan nomor WhatsApp dan password yang sudah terdaftar di akun bakat</li>
                            <li>Kemudian klik “Kelas Saya” untuk melakukan redeem voucher</li>
                            <li>Masukkan kode voucher kemudian klik “Redeem”</li>
                            <li>Kelas Anda akan muncul di bawah kolom redeem kode voucher, pada menu "Kelas Saya"</li>
                            <li>Klik kelas yang Anda ikuti</li>
                            <li>Klik menu "Aktivitas", ikuti kelas hingga progressnya 100%</li>

                            <!-- <li>Klik <b>"tukarkan kode voucher"</b> atau masuk ke <a href="https://www.prakerja.karier.mu/tukar-voucher" target="_blank">https://www.prakerja.karier.mu/tukar-voucher</a> </li>
                            <li>Masukkan kode voucher pada kolom yang tersedia</li>
                            <li>Klik <b>“Tukar Kode Voucher"</b></li>
                            <li>Klik <b>“Mulai Belajar”</b> dan program yang dibeli dapat dilihat juga pada menu <b>“Programmu”</b></li> -->
                          </ul>
                        </li>
                      </ol>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Cara Pembelian Pelatihan di Pintar </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol class="mb-2">
                        <li>Akses web <a href="https://pintar.co/kartuprakerja/cari/hasil?keyword=yec">https://pintar.co/kartuprakerja/cari/hasil?keyword=yec</a></li>
                        <li>Buat akun di Pintar dengan klik Daftar/Masuk</li>
                        <li>Lengkapi data diri Anda, kemudian <b>“klik daftar”</b></li>
                        <li>Apabila telah memiliki akun Pintar, silahkan Log in menggunakan email dan pasword yang telah didaftarkan dilink berikut <a href="https://sso.pintar.co/">https://sso.pintar.co/</a> </li>
                        <li>Pilih Menu Kartu Prakerja</li>
                        <li>Schroll kebawah untuk melihat pilihan kursus atau cari di pencarian</li>
                        <li>Pilih kursus yang Anda minati, dengan klik <b>“ikuti kursus”</b></li>
                        <li>Silahkan masukkan No Ponsel Anda</li>
                        <li>Lanjutkan Pembayaran dengan memasukkan ID Prakerja dan Kode OTP kemudian klik bayar</li>
                        <li>Setelah pembayaran berhasil Anda akan mendapatkan “Kode Voucher”, dapat dilihat di detail transaksi</li>
                        <li>
                          Langkah berikutnya silahkan tukarkan kode vouchermu, berikut prosedur penukaran Kode Voucher: 
                          <ul>
                            <li>Silakan masuk ke web <a href="https://bakat.yec.co.id/" target="_blank">https://bakat.yec.co.id/</a> </li>
                            <li>Pilih "Daftar" untuk membuat akun atau "Log in" menggunakan nomor WhatsApp dan password yang sudah terdaftar di akun bakat</li>
                            <li>Kemudian klik “Kelas Saya” untuk melakukan redeem voucher</li>
                            <li>Masukkan kode voucher kemudian klik “Redeem”</li>
                            <li>Kelas Anda akan muncul di bawah kolom redeem kode voucher, pada menu "Kelas Saya"</li>
                            <li>Klik kelas yang Anda ikuti</li>
                            <li>Klik menu "Aktivitas", ikuti kelas hingga progressnya 100%</li>

                            <!-- <li>Log in ke prakerja.karier.mu</li>
                            <li>Klik "tukarkan kode voucher" atau masuk ke <a href="https://www.prakerja.karier.mu/tukar-voucher" target="_blank">https://www.prakerja.karier.mu/tukar-voucher </a></li>
                            <li>Masukkan kode voucher pada kolom yang tersedia</li>
                            <li>Klik “Tukar Kode Voucher" </li>
                            <li>Klik “Mulai Belajar” dan program yang dibeli dapat dilihat juga pada menu “Programmu”</li> -->
                          </ul>
                        </li>
                      </ol>                     
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Cara Pembelian Pelatihan di Tokopedia </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <ol class="mb-2">
                        <li>Pastikan sudah memiliki akun di Tokopedia</li>
                        <li>Pilih kursus YEC.CO.ID yang sudah disediakan platform Tokopedia</li>
                        <li>Untuk membantu mencari kursus yang Anda inginkan, dapat menggunakan fitur Search,
                        dengan ketik nama pelatihan sesuai yang kamu inginkan.</li>
                        <li>Kemudian klik “Beli” pada kelas yang Anda minati</li>
                        <li>Pilih metode bayar dengan “Kartu Prakerja”</li>
                        <li>Klik "Lanjutkan", dan masukkan nomor kartu prakerja, dan klik "Bayar"</li>
                        <li>Masukan kode verifikasi atau OTP</li>
                        <li>Setelah pembayaran berhasil Anda akan mendapatkan “Kode Voucher”, dapat dilihat di detail transaksi</li>
                        <li>
                          Langkah berikutnya silahkan tukarkan kode vouchermu, berikut prosedur penukaran Kode Voucher:
                          <ul>
                            <li>Silakan masuk ke web <a href="https://bakat.yec.co.id/" target="_blank">https://bakat.yec.co.id/</a></li>
                            <li>Pilih "Daftar" untuk membuat akun atau "Log in" menggunakan nomor WhatsApp dan password yang sudah terdaftar di akun bakat</li>
                            <li>Kemudian klik “Kelas Saya” untuk melakukan redeem voucher</li>
                            <li>Masukkan kode voucher kemudian klik “Redeem”</li>
                            <li>Kelas Anda akan muncul di bawah kolom redeem kode voucher, pada menu "Kelas Saya"</li>
                            <li>Klik kelas yang Anda ikuti</li>
                            <li>Klik menu "Aktivitas", ikuti kelas hingga progressnya 100%</li>

                            <!-- <li>Klik "tukarkan kode voucher" atau masuk ke <a href="https://www.prakerja.karier.mu/tukar-voucher" target="_blank">https://www.prakerja.karier.mu/tukar-voucher </a></li>
                            <li>Masukkan kode voucher pada kolom yang tersedia</li>
                            <li>Klik “Tukar Kode Voucher" </li>
                            <li>Klik “Mulai Belajar” dan program yang dibeli dapat dilihat juga pada menu “Programmu”</li> -->
                          </ul>
                        </li>                        
                      </ol>                     
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                    <!-- <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Bagaimana Cara Reedem Voucher Kelas Prakerja YEC CO ID di Tokopedia? </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ol class="mb-2">
                          <li>Pastikan sudah memiliki akun di Tokopedia</li>
                          <li>Pilih kursus YEC.CO.ID yang sudah disediakan platform Tokopedia</li>
                          <li>Untuk membantu mencari kursus yang Anda inginkan, dapat menggunakan fitur Search,
                          dengan ketik nama pelatihan sesuai yang kamu inginkan.</li>
                          <li>Klik Beli</li>
                          <li>Pilih metode bayar dengan “Kartu Prakerja”</li>
                          <li>Klik Lanjutkan, dan masukkan nomor kartu prakerja, dan klik bayar</li>
                          <li>Masukan kode verifikasi atau OTP</li>
                          <li>Cek detail transaksi, catat kode voucher dan salin</li>
                          <li>Langkah selanjutnya, </li>
                          <li>Silahkan masuk ke web <a href="https://bakat.yec.co.id/">https://bakat.yec.co.id/</a> </li>
                          <li>Apabila belum mempunyai akun YEC, silahkan daftar akun terlebih dahulu dengan klik <b>“Daftar”</b></li>
                          <li>Setelah memiliki akun, silahkan Log in menggunakan nomer WA dan pasword yang sudah didaftarkan.</li>
                          <li>Silahkan isi data diri Anda</li>
                          <li>Kemudian klik <b>“Kelas Saya”</b> untuk melakukan redeem voucher</li>
                          <li>Masukkan kode voucher kemudian klik <b>“redeem”</b></li>
                          <li>Kelas yang Anda beli akan muncul, silahkan mulai mengikuti kelas Anda</li>
                        </ol>
                      </v-expansion-panel-content>
                    </v-expansion-panel> -->
                    <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Prosedur Penukaran Kode Voucher </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p>Setelah mendapatkan Kode Voucher, silahkan tukarkan Kode Vouchermu. Berikut langkah-langkahnya:</p>
                      <ol class="mb-2">
                        <li>Silakan masuk ke web <a href="https://bakat.yec.co.id/" target="_blank">https://bakat.yec.co.id/</a></li>
                        <li>Pilih "Daftar" untuk membuat akun atau "Log in" menggunakan nomor WhatsApp dan password yang sudah terdaftar di akun bakat</li>
                        <li>Kemudian klik “Kelas Saya” untuk melakukan redeem voucher</li>
                        <li>Masukkan kode voucher kemudian klik “Redeem”</li>
                        <li>Kelas Anda akan muncul di bawah kolom redeem kode voucher, pada menu "Kelas Saya"</li>
                        <li>Klik kelas yang Anda ikuti</li>
                        <li>Klik menu "Aktivitas", ikuti kelas hingga progressnya 100%</li>

                        <!-- <li>Log in ke prakerja.karier.mu</li>
                        <li>Klik "tukarkan kode voucher" atau masuk ke <a href="https://www.prakerja.karier.mu/tukar-voucher" target="_blank">https://www.prakerja.karier.mu/tukar-voucher </a></li>
                        <li>Masukkan kode voucher pada kolom yang tersedia</li>
                        <li>Klik “Tukar Kode Voucher" </li>
                        <li>Klik “Mulai Belajar” dan program yang dibeli dapat dilihat juga pada menu “Programmu”</li> -->
                      </ol>                   
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-expansion-panel>
                    <v-expansion-panel-header class="font-weight-bold">Cara Melihat Kode Voucher</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <p class="font-weight-bold">Tokopedia:</p>
                      <ol class="mb-2">
                        <li>Pada tampilan awal Tokopedia, kamu bisa masuk ke Akun.</li>
                        <li>Pada daftar Transaksi, pilih Top Up & Tagihan.</li>
                        <li>Selanjutnya akan muncul tampilan info transaksi yang sudah berhasil beserta informasi kode voucher yang sudah kamu pakai seperti dibawah ini:</li>
                      </ol>
                      <p>
                        Atau bisa cek di sini : <a href="https://www.tokopedia.com/help/article/cara-menemukan-kode-voucher" target="_blank">https://www.tokopedia.com/help/article/cara-menemukan-kode-voucher </a>
                      </p>

                      <p class="font-weight-bold">Karier.mu:</p>
                      <ol class="mb-2">
                        <li>Buka website sekolah.mu https://www.karier.mu</li>
                        <li>Klik akun profil</li>
                        <li>Pilih riwayat pembelian</li>
                        <li>Klik lihat detail pembelian</li>
                        <li>Kode voucher dihighlight warna hijau</li>
                      </ol>
                      <p>
                        Atau bisa cek di sini : <a href="https://cdn.sekolah.mu/upload/7cd6c8272e894d22bebf0ca0fe4aaee8.pdf" target="_blank">https://cdn.sekolah.mu/upload/7cd6c8272e894d22bebf0ca0fe4aaee8.pdf </a>
                      </p>

                      <p class="font-weight-bold">Pintar:</p>
                      <ol class="mb-2">
                        <li>Pastikan kamu sudah log in akun Pintar dan berhasil melakukan pembelian kelas</li>
                        <li>Pilih menu “Kelas Saya”</li>
                        <li>Pilih Lihat Voucher</li>
                      </ol>

                      <p class="font-weight-bold">Pijar Mahir:</p>
                      <ol class="mb-2">
                        <li>Pastikan pembelian kelasmu berhasil </li>
                        <li>Silahkan Log in ke akun Pijar Mahir </li>
                        <li>Jika sudah masuk pada halaman website Pijar Mahir klik profil kamu </li>
                        <li>Kemudian kalian bisa klik menu “voucher saya” dan lihat detail kelas yang kamu beli</li>
                      </ol>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="font-weight-bold">Kontak Admin YEC Prakerja </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ul class="mb-2">
                          <li>
                            Whatsapp 087758014542 atau <br>
                            klik link berikut <a href="http://bit.ly/whatsappadminyec">http://bit.ly/whatsappadminyec</a>
                          </li>
                          <li>
                            Group FB Panduan Kartu Prakerja <br>
                            <a href="https://www.facebook.com/groups/prakerja.yec">
                              <div>https://www.facebook.com/groups/prakerja.yec</div>
                            </a>
                          </li>
                          <li>
                            Group Telegram Panduan Prakerja by YEC <br>
                            <a href="https://t.me/joinchat/S682QP2B7N2mp2Cq">https://t.me/joinchat/S682QP2B7N2mp2Cq</a>
                          </li>
                          <li>Email : prakerja@yec.co.id</li>
                          <li>Telepon interaktif : (0354) 394 848</li>
                        </ul>                   
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
  </div>
</template>

<script>
// import resources from "@/store/resources"

export default {
  name: "public-prakerja",
  components: {   },
  data: () => ({
    // model:null,
    // resources: [],
  }),
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>
#tab-ver .v-list-item-group .v-list-item--active {
    color: #f05326;
    border-left: 2px solid;
}
.sticky-sidebar{
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}
</style>