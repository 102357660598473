<template>
	<v-row :no-gutters="$vuetify.breakpoint.smAndUp">
		<div class="font-weight-bold mb-3">{{ act.title }}</div>
		<v-card elevation="0" style="min-height: 60vh; width: 100%">
			<br /><br /><br /><br />
			<div class="pa-3 text-center">
				<p class="" v-html="act.theory.description"></p>
				<img src="@/assets/images/input-file.png" class="text-center mb-2" />
				<br />
				<v-btn
					rounded
					download
					depressed
					color="primary"
					class="mx-auto text-center"
					:href="act.theory.file.url"
					target="_blank"
					:loading="btn_loading"
					v-if="act.is_complete === true"
				>
					Download
				</v-btn>
				<v-btn
					rounded
					download
					depressed
					color="primary"
					class="mx-auto text-center"
					:href="act.theory.file.url"
					target="_blank"
					:loading="btn_loading"
					@click="getUnlock()"
					v-else
				>
					Download
				</v-btn>
			</div>
		</v-card>
	</v-row>
</template>

<script>

export default {
	props: {
		act: Object,
		id_topic: String,
		id_act: String,
	},
	data() {
		return {
			btn_loading: false,
		}
	},
	methods: {
		getUnlock() {
			this.btn_loading = true
			const data = {
				program_id: this.$route.params.id,
				topic_id: this.id_topic,
				activity_id: this.id_act,
			};
			this.axios.post(`/users/v1/member/activity/unlock`, data)
			.then(async () => {
				this.btn_loading = false
				this.$emit('getDetailClass')
			})
			.catch(() => {
				this.btn_loading = false
			});
		},
	}
}
</script>